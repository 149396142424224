import { useEffect, useState } from 'react';
import styles from './Settings.module.scss';
import { useAppDispatch, useAppSelector } from 'app/store';

import {
  selectHasBoughtSubscription,
  selectSubscription,
  authActions,
} from 'app/store/slices';
import { ProfileTab } from './ProfileTab/ProfileTab';
import { SubscriptionTab } from './SubscriptionTab/SubscriptionTab';
import { useNavigate } from 'react-router-dom';

type SelectedSection = 'Profile' | 'Subscription';
type Section = { title: string; onClick: () => void };

const Settings = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const subscription = useAppSelector(selectSubscription);
  const hasBoughtSubscription = useAppSelector(selectHasBoughtSubscription);
  const { setHasBoughtSubscription } = authActions;

  const [activeTab, setActiveTab] = useState<SelectedSection>(() =>
    subscription && subscription !== 'Expired' ? 'Profile' : 'Subscription'
  );
  const tabs: Section[] = [
    { title: 'Profile', onClick: () => onTabClick('Profile') },
    { title: 'Subscription', onClick: () => onTabClick('Subscription') },
  ];

  const onTabClick = (section: SelectedSection) => {
    if (subscription && subscription !== 'Expired') {
      setActiveTab(section);
    }
  };

  useEffect(() => {
    if (hasBoughtSubscription) {
      navigate('/wallets');
      dispatch(setHasBoughtSubscription(false));
    }
  }, [hasBoughtSubscription]);

  return (
    <div className={styles['settings-box']}>
      <div className={styles['tab-panel']}>
        <ul>
          {tabs.map((tab) => {
            const liClassName =
              activeTab === tab.title
                ? `${styles.tab} ${styles['tab-active']}`
                : styles.tab;
            const buttonClassName =
              activeTab === tab.title ? styles['button-active'] : '';

            return (
              <li key={tab.title} className={liClassName}>
                <button onClick={tab.onClick} className={buttonClassName}>
                  {tab.title}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
      {activeTab === 'Profile' ? <ProfileTab /> : <SubscriptionTab />}
    </div>
  );
};

export default Settings;
