import { FC } from 'react';
import styles from './ErrorMessage.module.scss';

type ErrorMessageProps = {
  className?: string;
  children: string;
};

const ErrorMessage: FC<ErrorMessageProps> = ({ children, className }) => {
  return (
    <div className={`${className} ${styles['error-message']}`}>{children}</div>
  );
};

export { ErrorMessage };
