import { dataHttp } from 'app/util/http';
import { Credits } from 'app/util/interfaces/credits.interface';
import { DecreaseCreditRequest } from 'app/util/requests/decrease-credits.request';
import { DecreaseCreditResponse } from 'app/util/responses/decrease-credits.response';

const api = dataHttp;

export function getCredits(): Promise<Credits> {
  return api.get('/v1/credit/credits');
}

export function decreaseCredits(
  credit: DecreaseCreditRequest
): Promise<DecreaseCreditResponse> {
  return api.get(`/v1/credit/decrease?credit=${credit}`);
}
