import { FC } from 'react';
import styles from './Sidebar.module.scss';
import { useAppDispatch, useAppSelector } from 'app/store';
import { selectIsOpen, setIsOpen } from 'app/store/slices';
import { Item } from './Item';
import {
  IconSwapHoriz,
  IconWhatsHot,
  IconBolt,
  IconHistory,
  IconWork,
  IconRightArrow,
  IconButton,
  IconLeftArrow,
  IconWallet,
  IconStarFilled,
  IconLunchDining,
} from 'app/components';

export type Item = {
  to: string;
  icon: JSX.Element;
  label: string;
};

const Sidebar: FC = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectIsOpen);

  const items: Item[] = [
    { to: '/wallets', icon: <IconWallet />, label: 'Wallets' },
    { to: '/swaps', icon: <IconSwapHoriz />, label: 'Best Swaps' },
    { to: '/buys', icon: <IconWhatsHot />, label: 'Hot Buys' },
    { to: '/simulator', icon: <IconBolt />, label: 'Profit Simulator' },
    { to: '/history', icon: <IconHistory />, label: 'Simulation History' },
    {
      to: '/favorites-overview',
      icon: <IconStarFilled />,
      label: 'Favorites Overview',
    },
    { to: '/arbitrages', icon: <IconWork />, label: 'Arbitrages' },
    { to: '/sandwich', icon: <IconLunchDining />, label: 'Sandwich' },
  ];

  const toggleIsOpen = (): void => {
    dispatch(setIsOpen(!isOpen));
  };

  return (
    <aside className={styles.aside}>
      <IconButton onClick={toggleIsOpen}>
        {isOpen ? <IconLeftArrow /> : <IconRightArrow />}
      </IconButton>
      <div className={styles.divider}></div>
      <ul>
        {items.map((item) => (
          <Item {...item} key={item.label} />
        ))}
      </ul>
    </aside>
  );
};

export { Sidebar };
