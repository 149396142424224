import {
  HistoryMetricsData,
  HistoryStatus,
  HistoryStatusOfSimulation,
} from 'app/models';
import { dataHttp } from '../util/http';

const api = dataHttp;

export function getHistoryMetrics(id: string): Promise<HistoryMetricsData> {
  return api.get(`/v1/simulation/data/metrics?simulation_id=${id}`);
}
export function getHistoryStatus(): Promise<HistoryStatus[] | string> {
  return api.get(`/v1/simulation/status/`);
}
export function deleteSimulation(id: string): Promise<string> {
  return api.delete(`/v1/simulation/status/${id}`);
}

export function getDateSimulation(
  id: string
): Promise<HistoryStatusOfSimulation> {
  return api.get(`/v1/simulation/status/${id}`);
}
