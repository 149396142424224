import { createAsyncThunk } from '@reduxjs/toolkit';
import { getWallet, getWallets } from 'app/api';
import { FiltValsWithPaginReq, WalletAndSnapshot } from 'app/util/requests';
import { IFail } from 'app/util/responses';
import { AxiosError } from 'axios';

enum WalletThunks {
  GET_BY_ADDRESS = 'wallets/getByAddress',
  GET_ALL = 'wallets/getAll',
}

export const fetchWallets = createAsyncThunk(
  WalletThunks.GET_ALL,
  async (params: FiltValsWithPaginReq, { rejectWithValue }) => {
    try {
      const response = await getWallets(params);
      return response;
    } catch (e) {
      const error = e as AxiosError<IFail>;
      return rejectWithValue(
        error.response?.data.detail || 'Unrecognized error happened'
      );
    }
  }
);

export const fetchWallet = createAsyncThunk(
  WalletThunks.GET_BY_ADDRESS,
  async (params: WalletAndSnapshot, { rejectWithValue }) => {
    const response = await getWallet(params);

    if (typeof response === 'string') {
      return rejectWithValue(
        'The wallet you searched does not exist in our database'
      );
    }

    return response;
  }
);
