import { ReactNode } from 'react';
import { Loader, Tooltip, ErrorMessage } from 'app/components';
import ExpandButton from '../ExpandButton/ExpandButton';
import CloseButton from '../CloseButton/CloseButton';
import styles from './PlotWrapper.module.scss';

interface PlotWrapperProps {
  title?: string;
  info?: JSX.Element;
  status?: 'loading' | 'error' | 'idle';
  children: ReactNode;
  fullscreen?: boolean;
  onExpandClick?: () => void;
  onCloseClick?: () => void;
  justifyCenter?: boolean;
}

const PlotWrapper = ({
  children,
  title,
  info,
  fullscreen,
  onExpandClick,
  onCloseClick,
  justifyCenter = true,
  status = 'idle',
}: PlotWrapperProps): JSX.Element => {
  return (
    <div
      className={
        fullscreen
          ? `${styles['chart-container']} ${styles['chart-fullscreen-container']}`
          : styles['chart-container']
      }
    >
      <div className={styles['chart-toolbar']}>
        <h1 className={styles['plot-title']}>
          <span>{title}</span>
        </h1>
        {status === 'idle' && (
          <div className={styles['tooltip-wrapper']}>
            <Tooltip size="large" position="bottom-left" text={info} />
          </div>
        )}

        {fullscreen === true && onCloseClick && (
          <CloseButton onClick={onCloseClick} />
        )}
        {fullscreen === false && onExpandClick && (
          <ExpandButton onClick={onExpandClick} />
        )}
      </div>
      {status === 'loading' ? (
        <Loader />
      ) : status === 'idle' ? (
        justifyCenter ? (
          <div className={styles['chart-body']}>{children}</div>
        ) : (
          children
        )
      ) : (
        <ErrorMessage className="mt-2">No data to visualize</ErrorMessage>
      )}
    </div>
  );
};

export { PlotWrapper };
