import { ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import { selectAccessToken } from 'app/store/slices';

interface AuthRouteProps {
  children: ReactElement;
}

const AuthRoute = ({ children }: AuthRouteProps): JSX.Element => {
  const location = useLocation();
  const accessToken = useAppSelector(selectAccessToken);

  if (!accessToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export { AuthRoute };
