import { PriceIds, Subscription } from 'app/util/interfaces';
import { FC, useEffect, useState } from 'react';
import { getPriceIds as fetchPriceIds } from 'app/api';
import { Loader, Switch, Button, BackToLoginButton } from 'app/components';
import { useAppSelector, useThunkDispatch } from 'app/store';
import {
  selectAccessToken,
  selectSubscription,
  authActions,
} from 'app/store/slices';
import styles from './SubscriptionTab.module.scss';
import { SubscriptionPlanCard } from './SubscriptionPlanCard/SubscriptionPlanCard';
import { authHttp } from 'app/util/http';
import { ShrinkDotsLoader } from './ShrinkDotsLoader/ShrinkDotsLoader';

const SubscriptionTab: FC = () => {
  const accessToken = useAppSelector(selectAccessToken);
  const subscription = useAppSelector(selectSubscription);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([
    {
      title: 'Trial',
      subtitle: 'Free Features',
      cognitoGroup: ['trial'],
      cost: {
        monthly: 5.99,
        annual: 5.99,
      },
      priceId: '',
      isRecommended: false,
      features: ['Access to all Basic Features'],
    },
    {
      title: 'Basic',
      subtitle: 'Basic Features',
      cognitoGroup: ['basic_BETA_monthly', 'basic_BETA_yearly'],
      cost: {
        monthly: 9.99,
        annual: 99.99,
      },
      priceId: {
        monthly: 'price_1LhLNkLlcM0bSmfzvw87iwm1',
        yearly: 'price_1LhLRSLlcM0bSmfz1h02SmB4',
      },
      isRecommended: false,
      features: [
        'Interactive Birds-Eye Market',
        'Various Profit Filters',
        'Atom Scale-Wallet Profiler',
        '3 Daily Filter & Simulation Credits',
        '3 Storage Credit for Favorites & Simulations ',
        '3 maximum Favorite List & Simulation Size ',
      ],
    },
    {
      title: 'Premium',
      subtitle: 'Premium Features',
      cognitoGroup: ['premium_BETA_monthly', 'premium_BETA_yearly'],
      cost: {
        monthly: 29.99,
        annual: 299.99,
      },
      priceId: {
        monthly: 'price_1LhLTrLlcM0bSmfzX7pc4CnV',
        yearly: 'price_1M0yedLlcM0bSmfz8oTRjhGW ',
      },
      isRecommended: false,
      features: [
        'All Basic Features Plus',
        'Filter bot wallets like MEV, Flash and High-Frequency',
        '10 Daily Filter & Simulation Credits',
        '10 Storage Credit for Favorites & Simulations ',
        '10 maximum Favorite List & Simulation Size ',
      ],
    },
    {
      title: 'Advanced',
      subtitle: 'Advanced Features',
      cognitoGroup: ['advanced_BETA_monthly', 'advanced_BETA_yearly'],
      cost: {
        monthly: 69.99,
        annual: 699.99,
      },
      priceId: {
        monthly: 'price_1LhLVQLlcM0bSmfz3AwMc2Uv',
        yearly: 'price_1LhLVQLlcM0bSmfzEWOvfjto',
      },
      isRecommended: false,
      features: [
        'All Premium Features Plus',
        '100 Daily Filter & Simulation Credits',
        '100 Storage Credit for Favorites & Simulations ',
        '100 maximum Favorite List & Simulation Size ',
        // 'API Access',
        'Community, Workshops and Early Access',
        // 'Feed your AI Algorithms with CSV data',
      ],
    },
    {
      title: 'Elite',
      subtitle: 'Elite Features',
      cognitoGroup: ['elite_BETA_monthly', 'elite_BETA_yearly'],
      cost: {
        monthly: 199.99,
        annual: 1999.99,
      },
      priceId: {
        monthly: 'price_1LhLWJLlcM0bSmfzCfGwI3VL',
        yearly: 'price_1M0yf1LlcM0bSmfzQeiATVQV ',
      },
      isRecommended: true,
      features: [
        'All Advanced Features Plus',
        '200 Daily Filter & Simulation Credits',
        '200 Storage Credit for Favorites & Simulations ',
        '200 maximum Favorite List & Simulation Size ',
      ],
    },
  ]);
  const [isLoadingPriceIds, setIsLoadingPriceIds] = useState(true);
  const [isLoadingManagementURL, setIsLoadingManagementURL] = useState(false);
  const [activeSubTerm, setActiveSubTerm] = useState<'monthly' | 'annual'>(
    'monthly'
  );
  const thunkDispatch = useThunkDispatch();
  const { logout } = authActions;

  const planPeriodChangeHandler = () => {
    setActiveSubTerm((currentSubTerm) => {
      if (currentSubTerm === 'annual') {
        return 'monthly';
      } else {
        return 'annual';
      }
    });
  };

  const updatePriceIds = (
    setSubscriptions: React.Dispatch<React.SetStateAction<Subscription[]>>,
    priceIds: PriceIds
  ) => {
    setSubscriptions((subscriptions) =>
      subscriptions.map((subscription) => {
        if (typeof subscription.priceId === 'string') {
          return {
            ...subscription,
            priceId: priceIds[subscription.cognitoGroup[0] as keyof PriceIds],
          };
        } else {
          return {
            ...subscription,
            priceId: {
              monthly: priceIds[subscription.cognitoGroup[0] as keyof PriceIds],
              yearly: priceIds[subscription.cognitoGroup[1] as keyof PriceIds],
            },
          };
        }
      })
    );
  };

  const getPriceIds = async () => {
    const response = await fetchPriceIds();
    updatePriceIds(setSubscriptions, response);
    setIsLoadingPriceIds(false);
  };

  const redirectToManageSubscription = async () => {
    setIsLoadingManagementURL(true);
    const { managementUrl } = await authHttp.post<
      void,
      { managementUrl: string }
    >(
      '/create-customer-portal-session',
      {},
      {
        headers: {
          'access-token': accessToken,
        },
      }
    );
    const managementTab = window.open();
    if (managementTab && 'opener' in managementTab) {
      managementTab.opener = null;
      managementTab.location = managementUrl;
      setIsLoadingManagementURL(false);
    }
  };

  useEffect(() => {
    getPriceIds();
  }, []);

  return isLoadingPriceIds ? (
    <Loader />
  ) : (
    <div>
      {subscription ? (
        subscription === 'Expired' ? (
          <>
            <header className="mb-4">
              <div className={styles['error-box']}>
                It looks like your subscription ran out. Please, renew it
              </div>
            </header>
            <div className="mb-4">
              <BackToLoginButton onClick={() => thunkDispatch(logout())} />
            </div>
          </>
        ) : (
          <header className="mb-4">
            <p>
              <strong>Current Subscription: </strong> {subscription}
            </p>
          </header>
        )
      ) : (
        <div className="mb-4">
          <BackToLoginButton onClick={() => thunkDispatch(logout())} />
        </div>
      )}

      <div>
        <span className={styles['switch-label']}>Show annual plans</span>
        <Switch
          name="planPeriod"
          checked={activeSubTerm === 'annual'}
          onChange={planPeriodChangeHandler}
        />

        <div className={styles['subscription-cards']}>
          {subscriptions.map((subscription) => (
            <SubscriptionPlanCard
              key={subscription.title}
              {...subscription}
              showAnnualPrice={activeSubTerm === 'annual'}
            />
          ))}
        </div>

        {subscription && (
          <Button
            onClick={redirectToManageSubscription}
            cssClasses={`bg-primary mt-4 ${styles['manage-sub-button']}`}
          >
            {isLoadingManagementURL ? (
              <ShrinkDotsLoader />
            ) : (
              'Manage Subscription'
            )}
          </Button>
        )}
      </div>
    </div>
  );
};

export { SubscriptionTab };
