import styles from './Tooltip.module.scss';
export type TooltipSize = 'small' | 'medium' | 'large';
export type TooltipPosition =
  | 'top-right'
  | 'top-left'
  | 'bottom-right'
  | 'bottom-left';

export interface TooltipProps {
  size: TooltipSize;
  text: React.ReactNode;
  position: TooltipPosition;
}

const Tooltip = ({ text, size, position }: TooltipProps): JSX.Element => {
  return (
    <div className={styles['tooltip']}>
      {'i '}
      <span className={`${styles['info']} ${styles[size]} ${styles[position]}`}>
        {text}
      </span>
    </div>
  );
};

export { Tooltip };
