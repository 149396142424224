/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';

import {
  getFromLocalStorage,
  removeFromLocalStorage,
  setToLocalStorage,
  defineSubscription,
} from 'app/util/helper-functions';
import { RootState } from '../store';
import { LoadingStatus } from 'app/util/enums';
import { login, refreshAccessToken } from '../thunks';
import { SubscriptionType } from 'app/util/interfaces';

const isTokenExpired = (expiry: number) => expiry < Date.now();

const accessToken = getFromLocalStorage('accessToken');
const tokenExpiry = getFromLocalStorage('tokenExpiry');
const isAuthenticated = accessToken
  ? isTokenExpired(tokenExpiry)
    ? false
    : true
  : false;

const getSubscription = (accessToken: string | null): SubscriptionType => {
  if (accessToken && !isTokenExpired(tokenExpiry)) {
    const decodedAccessToken = jwtDecode(accessToken) as any;
    return defineSubscription(decodedAccessToken['cognito:groups']);
  }
  return null;
};

interface AuthState {
  username: string;
  email: string;
  accessToken: string;
  refreshToken: string;
  tokenExpiry: number;
  loginStatus: LoadingStatus;
  refreshAccessTokenStatus: LoadingStatus;
  loginError: string;
  refreshAccessTokenError: string;
  isAuthenticated: boolean;
  subscription: SubscriptionType;
  hasBoughtSubscription: boolean;
}

const initialState: AuthState = {
  accessToken,
  tokenExpiry,
  isAuthenticated,
  username: getFromLocalStorage('username'),
  email: getFromLocalStorage('email'),
  refreshToken: getFromLocalStorage('refreshToken'),
  loginStatus: LoadingStatus.IDLE,
  refreshAccessTokenStatus: LoadingStatus.IDLE,
  loginError: '',
  refreshAccessTokenError: '',
  subscription: getSubscription(accessToken),
  hasBoughtSubscription: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.username = '';
      state.email = '';
      state.accessToken = '';
      state.tokenExpiry = 0;
      state.refreshToken = '';
      state.loginStatus = LoadingStatus.IDLE;
      state.refreshAccessTokenStatus = LoadingStatus.IDLE;
      state.loginError = '';
      state.refreshAccessTokenError = '';
      state.isAuthenticated = false;
      removeFromLocalStorage('accessToken');
      removeFromLocalStorage('tokenExpiry');
      removeFromLocalStorage('email');
      removeFromLocalStorage('username');
      removeFromLocalStorage('refreshToken');
    },
    resetLoginStatus: (state) => {
      state.loginStatus = LoadingStatus.IDLE;
    },
    setHasBoughtSubscription: (state, action: PayloadAction<boolean>) => {
      state.hasBoughtSubscription = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loginStatus = LoadingStatus.PENDING;
      })
      .addCase(login.fulfilled, (state, action) => {
        const status = action.payload.status;
        if (typeof status === 'string') {
          state.loginStatus = LoadingStatus.REJECTED;
          state.loginError = status;
          return;
        }

        const { AccessToken, IdToken, RefreshToken } =
          status.AuthenticationResult;

        const decodedAccessToken = jwtDecode(AccessToken) as any;
        state.subscription = defineSubscription(
          decodedAccessToken['cognito:groups']
        );
        state.accessToken = AccessToken;
        state.refreshToken = RefreshToken;
        state.username = decodedAccessToken.username;
        state.tokenExpiry = decodedAccessToken.exp * 1000;
        state.isAuthenticated = true;
        setToLocalStorage('accessToken', AccessToken);
        setToLocalStorage('refreshToken', RefreshToken);
        setToLocalStorage('tokenExpiry', decodedAccessToken.exp * 1000);

        const decodedIdToken = jwtDecode(IdToken) as any;
        state.email = decodedIdToken.email;
        setToLocalStorage('email', decodedIdToken.email);
        setToLocalStorage('username', decodedIdToken['cognito:username']);

        state.loginStatus = LoadingStatus.FULFILLED;
      })
      .addCase(login.rejected, (state, action) => {
        state.loginStatus = LoadingStatus.REJECTED;
        state.loginError = action.error.message as string;
      })
      .addCase(refreshAccessToken.fulfilled, (state, action) => {
        const status = action.payload.status;
        if (typeof status === 'string') {
          state.refreshAccessTokenStatus = LoadingStatus.REJECTED;
          state.refreshAccessTokenError = status;
          return;
        }

        const { AccessToken } = status.AuthenticationResult;

        const decodedAccessToken = jwtDecode(AccessToken) as any;
        state.subscription = defineSubscription(
          decodedAccessToken['cognito:groups']
        );
        state.accessToken = AccessToken;
        state.tokenExpiry = decodedAccessToken.exp * 1000;
        state.isAuthenticated = true;
        setToLocalStorage('accessToken', AccessToken);
        setToLocalStorage('tokenExpiry', decodedAccessToken.exp * 1000);
      })
      .addCase(refreshAccessToken.rejected, (state, action) => {
        state.refreshAccessTokenError = action.error.message as string;
      });
  },
});

export const authActions = authSlice.actions;

export const selectAccessToken = (state: RootState) => state.auth.accessToken;
export const selectRefreshToken = (state: RootState) => state.auth.refreshToken;
export const selectIsAuthenticated = (state: RootState) =>
  state.auth.isAuthenticated;
export const selectLoginError = (state: RootState) => state.auth.loginError;
export const selectSubscription = (state: RootState) => state.auth.subscription;
export const selectLoginStatus = (state: RootState) => state.auth.loginStatus;
export const selectHasBoughtSubscription = (state: RootState) =>
  state.auth.hasBoughtSubscription;

const authReducer = authSlice.reducer;

export default authReducer;
