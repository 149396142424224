import { createSlice } from '@reduxjs/toolkit';
import { LoadingStatus } from 'app/util/enums';
import { RouterResponse } from 'app/util/responses';
import { RootState } from '../store';
import { fetchRouter, fetchRouters } from '../thunks';

type InitState = {
  routers: {
    [key: string]: RouterResponse;
  };
  status: LoadingStatus;
};

const initialState: InitState = {
  routers: {},
  status: LoadingStatus.IDLE,
};

const routers = createSlice({
  name: 'routers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRouter.pending, (state) => {
        state.status = LoadingStatus.PENDING;
      })
      .addCase(fetchRouter.fulfilled, (state, action) => {
        state.routers[action.payload.contract_address] = action.payload;
        state.status = LoadingStatus.FULFILLED;
      })
      .addCase(fetchRouters.pending, (state) => {
        state.status = LoadingStatus.PENDING;
      })
      .addCase(fetchRouters.fulfilled, (state, action) => {
        action.payload.forEach((router) => {
          state.routers[router.contract_address] = router;
        });
        state.status = LoadingStatus.FULFILLED;
      });
  },
});

export default routers.reducer;

export const selectRouters = (state: RootState) => state.routers.routers;
