import { FC } from 'react';
import s from './BackToLogin.module.scss';
import { IconLeftArrow } from 'app/components';

type BackToLoginButtonProps = {
  onClick: () => void;
};

const BackToLoginButton: FC<BackToLoginButtonProps> = ({ onClick }) => {
  return (
    <button onClick={onClick} className={`${s['logout-button']}`}>
      <IconLeftArrow />
      <span className={s['logout-button-text']}></span> Back to login
    </button>
  );
};

export { BackToLoginButton };
