import { FC } from 'react';
import styles from './CloseButton.module.scss';

type CloseButtonProps = {
  onClick: () => void;
};

const CloseButton: FC<CloseButtonProps> = ({ onClick }) => {
  return (
    <button onClick={onClick} className={styles['close-button']}>
      x
    </button>
  );
};

export default CloseButton;
