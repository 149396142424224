import { createSlice } from '@reduxjs/toolkit';
import { Buy } from 'app/models';
import { RootState } from '../store';
import { fetchAllBuys } from '../thunks';

export interface BuyState {
  items: Buy[];
  status: 'loading' | 'idle' | 'error';
  error: string;
  pages: number;
}

const initialState: BuyState = {
  items: [],
  status: 'loading',
  error: '',
  pages: 0,
};

export const buySlice = createSlice({
  name: 'buy',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllBuys.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllBuys.fulfilled, (state, action) => {
        state.items = action.payload.data;
        state.pages = action.payload.page_data.last_page;
        state.status = 'idle';
      })
      .addCase(fetchAllBuys.rejected, (state, action) => {
        state.items = [];
        state.error = action.payload as string;
        state.status = 'error';
      });
  },
});

export const buyActions = buySlice.actions;

export const selectBuys = (state: RootState): Buy[] => state.buy.items;
export const selectBuysStatus = (state: RootState) => state.buy.status;
export const selectBuysError = (state: RootState): string => state.buy.error;
export const selectBuysPages = (state: RootState): number => state.buy.pages;

const buyReducer = buySlice.reducer;

export default buyReducer;
