import { createSlice } from '@reduxjs/toolkit';
import { LoadingStatus } from 'app/util/enums';
import { DefaultWalletFilters } from 'app/util/interfaces/default-wallet-filters.interface';
import { RootState } from '../store';
import { getDefaultWalletFilters } from '../thunks';

type FilterState = {
  wallet: DefaultWalletFilters & {
    status: LoadingStatus;
    errorTxt: string;
  };
};

const initialState: FilterState = {
  wallet: {
    is_top_trader: 'any',
    is_flash_bot: 'any',
    min_trades: '',
    max_trades: '',
    min_volume: '',
    max_volume: '',
    min_profit: '',
    max_profit: '',
    min_avg_invest_size: '',
    max_avg_invest_size: '',
    min_win_loss_ratio: '',
    min_avg_roi_per_trade: '',
    page: '',
    page_size: '',
    is_mev_bot: 'any',
    is_high_freq_bot: 'any',
    status: LoadingStatus.IDLE,
    errorTxt: '',
    is_arbitrageur: 'any',
    is_sandwicher: 'any',
    is_human: 'any',
  },
};

const filter = createSlice({
  name: 'filter',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDefaultWalletFilters.pending, (state) => {
      state.wallet.status = LoadingStatus.PENDING;
    });

    builder.addCase(getDefaultWalletFilters.fulfilled, (state, action) => {
      state.wallet = {
        ...action.payload,
        status: LoadingStatus.FULFILLED,
        errorTxt: '',
      };
    });

    builder.addCase(getDefaultWalletFilters.rejected, (state, action) => {
      state.wallet.status = LoadingStatus.REJECTED;
      state.wallet.errorTxt = action.payload as string; // Don't know what in error
    });
  },
});

export const selectWalletFiltersStatus = (state: RootState) =>
  state.filter.wallet.status;
export const selectWalletFilters = (state: RootState) => state.filter.wallet;

const filterReducer = filter.reducer;
export default filterReducer;
