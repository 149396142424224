const IconExpand = (): JSX.Element => (
  <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.143 3.572h4.285m0 0v4.285m0-4.285-5 5m-3.571 7.857H3.571m0 0v-4.286m0 4.286 5-5"
      // stroke="#888"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { IconExpand };
