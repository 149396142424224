import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Favorite } from 'app/models';
import { useAppSelector, useThunkDispatch } from 'app/store';
import { selectFavorites } from 'app/store/slices';
import { fetchAllFavorites } from 'app/store/thunks';
import { FavoritesRequest } from 'app/util/requests';
import { Select } from 'app/components';
import styles from './CustomList.module.scss';
import { logFilterChange } from 'services';

const CustomList = (): JSX.Element => {
  const [favoriteParams] = useState<FavoritesRequest>({
    only_names: false,
  });

  const thunkDispatch = useThunkDispatch();

  const favorites = useAppSelector(selectFavorites);

  useEffect(() => {
    thunkDispatch(fetchAllFavorites({ ...favoriteParams }));
  }, []);
  const methods = useFormContext();
  const customListValue = methods.watch('collection_id');
  useEffect(() => {
    if (customListValue) {
      logFilterChange('custom-list', customListValue);
    }
  }, [customListValue]);

  const options: Favorite[] = [
    {
      name: 'Not selected',
      collection_items: [],
    },
    ...favorites,
  ];

  return (
    <div className={styles['custom-list-wrapper']}>
      <span className={styles['header']}>Custom List</span>

      <Controller
        control={methods.control}
        name="collection_id"
        render={({ field: { onChange, value, onBlur } }) => (
          <Select
            options={options}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
      />
      <p className={styles['error-message']}>
        {methods.formState.errors.collection_id?.message ? 'Not selected!' : ''}
      </p>
    </div>
  );
};

export { CustomList };
