import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface InitState {
  isMenuOpen: boolean;
}

const initialState: InitState = {
  isMenuOpen: false,
};

export const header = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setIsMenuOpen(state, action: PayloadAction<boolean>) {
      state.isMenuOpen = action.payload;
    },
  },
});

export default header.reducer;

export const { setIsMenuOpen } = header.actions;
export const selectIsMenuOpen = (state: RootState) => state.header.isMenuOpen;
