import styles from './SearchInput.module.scss';
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { IconSearch, IconButton, IconClose } from 'app/components/';
import { Link } from 'react-router-dom';
import { SearchInputProps } from 'app/util/interfaces/search-input-props.interface';

const SearchInputMobile: FC<SearchInputProps> = ({
  placeholder,
  value,
  onChange,
  onSearch,
}) => {
  const [topDrawerStatus, setTopDrawerStatus] = useState<
    'first-render' | 'close' | 'open'
  >('first-render');
  const [searchValue, setSearchValue] = useState('');
  const [validation, setValidation] = useState('outline-none');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const topDrawerRef = useRef<HTMLInputElement | null>(null);

  const searchHandler = () => {
    setValidation('outline-none');

    if (onSearch && searchValue.length > 0) {
      onSearch(searchValue);
    }
    if (searchValue.match(/^0x[a-fA-F0-9]{40}$/)) {
      setValidation('outline-none');
    } else {
      setValidation('outline-red');
    }
  };

  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
    setValidation('outline-none');

    if (onChange) {
      onChange(value);
    }
  };

  interface ConditionalLinkProps {
    to: string;
    children: JSX.Element;
  }

  const ConditionalLink = ({ children, to }: ConditionalLinkProps) =>
    searchValue.match(/^0x[a-fA-F0-9]{40}$/) ? (
      <Link to={to}>{children}</Link>
    ) : (
      <>{children}</>
    );

  useEffect(() => {
    if (topDrawerStatus === 'open') {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [topDrawerStatus]);

  return (
    <>
      <div
        ref={topDrawerRef}
        className={
          topDrawerStatus === 'first-render'
            ? styles['d-none']
            : topDrawerStatus === 'open'
            ? styles['top-drawer-open']
            : styles['top-drawer-close']
        }
      >
        <div
          className={`${styles['search-input-wrapper-mobile']} ${styles[validation]} mr-2`}
        >
          <input
            ref={inputRef}
            className={styles['search-input']}
            placeholder={placeholder}
            type="search"
            value={value}
            spellCheck={false}
            onChange={inputChangeHandler}
          />
          <ConditionalLink to={`/wallets/${searchValue}/dashboard`}>
            <button
              className={styles['search-button']}
              aria-label="search"
              onClick={searchHandler}
            >
              <IconSearch />
            </button>
          </ConditionalLink>
        </div>
        <IconButton onClick={() => setTopDrawerStatus('close')}>
          <IconClose></IconClose>
        </IconButton>
      </div>
      <IconButton onClick={() => setTopDrawerStatus('open')}>
        <IconSearch></IconSearch>
      </IconButton>
    </>
  );
};

export { SearchInputMobile };
