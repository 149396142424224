import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSimulatorBreakdownPlot } from 'app/api';

enum SimulatorThunks {
  GET_BREAKDOWN = 'simulator/getBreakdownPlot',
}

export const fetchBreakdownPlot = createAsyncThunk(
  SimulatorThunks.GET_BREAKDOWN,
  (id: string) => {
    return getSimulatorBreakdownPlot(id);
  }
);
