import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from 'app/util/enums';
import { RootState } from '../store';
import { startSimulation } from '../thunks';

interface SimulatorState {
  simulationId: string;
  simulationIdStatus: LoadingStatus;
  simulationIdErrorTxt: string;
  collectionName: string;
}

const initialState: SimulatorState = {
  simulationId: '',
  simulationIdStatus: LoadingStatus.IDLE,
  simulationIdErrorTxt: '',
  collectionName: 'Top 10',
};

export const simulatorSlice = createSlice({
  name: 'simulator',
  initialState,
  reducers: {
    setSimulationId: (state, action: PayloadAction<string>) => {
      state.simulationId = action.payload;
    },
    setSimulationIdStatus: (state, { payload }) => {
      state.simulationIdStatus = payload;
    },
    setCollectionName: (state, action: PayloadAction<string>) => {
      state.collectionName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(startSimulation.pending, (state) => {
        state.simulationIdStatus = LoadingStatus.PENDING;
      })
      .addCase(startSimulation.fulfilled, (state, action) => {
        state.simulationIdErrorTxt = '';
        state.simulationIdStatus = LoadingStatus.FULFILLED;
        state.simulationId = action.payload;
        state.collectionName = action.meta.arg.collection_id;
      })
      .addCase(startSimulation.rejected, (state, action) => {
        state.simulationIdStatus = LoadingStatus.REJECTED;
        state.simulationIdErrorTxt = action.payload as string;
      });
  },
});

export const simulatorActions = simulatorSlice.actions;

export const selectSimulationIdStatus = (state: RootState) =>
  state.simulator.simulationIdStatus;
export const selectSimulationIdErrorTxt = (state: RootState) =>
  state.simulator.simulationIdErrorTxt;
export const selectSimulationId = (state: RootState) =>
  state.simulator.simulationId;
export const selectCollectionName = (state: RootState) =>
  state.simulator.collectionName;

const simulatorReducer = simulatorSlice.reducer;

export default simulatorReducer;
