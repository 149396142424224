import { createSlice } from '@reduxjs/toolkit';

import { Swap } from 'app/models';
import { RootState } from '../store';
import { getSwapsData } from '../thunks';

export interface SwapState {
  items: Swap[];
  itemsStatus: 'loading' | 'idle' | 'error';
  error: string;
  totalPages: number;
}

const initialState: SwapState = {
  items: [],
  itemsStatus: 'loading',
  error: '',
  totalPages: 0,
};

export const swapSlice = createSlice({
  name: 'swap',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSwapsData.pending, (state) => {
        state.itemsStatus = 'loading';
      })
      .addCase(getSwapsData.fulfilled, (state, action) => {
        state.items = action.payload.data;
        state.totalPages = action.payload.page_data.last_page;
        state.itemsStatus = 'idle';
      })
      .addCase(getSwapsData.rejected, (state, action) => {
        state.items = [];
        state.error = action.payload as string;
        state.itemsStatus = 'error';
      });
  },
});

export const swapActions = swapSlice.actions;

export const selectSwaps = (state: RootState): Swap[] => state.swap.items;
export const selectSwapsStatus = (state: RootState) => state.swap.itemsStatus;
export const selectSwapsTotalPages = (state: RootState): number =>
  state.swap.totalPages;
export const selectSwapsError = (state: RootState) => state.swap.error;

const swapReducer = swapSlice.reducer;

export default swapReducer;
