import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTokenLogo, getTokenLogos } from 'app/api';

enum TokenThunks {
  GET_LOGO = 'tokens/getLogo',
  GET_LOGOS = 'tokens/getLogos',
  GET_ROUTER = 'routers/getRouter',
  GET_ROUTERS = 'routers/getRouters',
}

export const fetchTokenLogo = createAsyncThunk(
  TokenThunks.GET_LOGO,
  (contractAddress: string) => {
    return getTokenLogo(contractAddress);
  }
);

export const fetchTokenLogos = createAsyncThunk(
  TokenThunks.GET_LOGOS,
  (contractAddresses: string[]) => {
    return getTokenLogos(contractAddresses);
  }
);
