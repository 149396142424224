import { Wallet } from 'app/models';
import { FiltValsWithPaginReq, WalletAndSnapshot } from 'app/util/requests';
import { SlimWallet, SlimWalletsResponse } from 'app/util/responses';
import { dataHttp } from '../util/http';

const client = dataHttp;

export function getWallets(
  params: FiltValsWithPaginReq
): Promise<SlimWalletsResponse> {
  return client.get(`/v1/wallets_slim`, { params });
}

export function getWallet({
  walletId,
  snapshot,
}: WalletAndSnapshot): Promise<Wallet | string> {
  const url = snapshot
    ? `/v1/wallets/${walletId}?snapshot=${snapshot}`
    : `/v1/wallets/${walletId}`;
  return client.get(url);
}

export function getSlimWallet({
  walletId,
  snapshot,
}: WalletAndSnapshot): Promise<SlimWallet> {
  return client.get(`/v1/slim_wallets/${walletId}`, { params: { snapshot } });
}
