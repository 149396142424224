import { Colors } from 'app/util/colors';

function IconAccountVerification() {
  return (
    <svg
      width="256"
      height="256"
      viewBox="0 0 364 326"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3184_71091)">
        <rect
          x="0.921875"
          y="125.057"
          width="361.823"
          height="199.614"
          rx="2"
          fill="white"
        />
        <path
          d="M360.807 125.057C360.677 125.057 360.55 125.017 360.443 124.943L183.833 2.60698C183.296 2.23642 182.657 2.03839 182.003 2.0395C181.349 2.04061 180.711 2.24081 180.175 2.61318L4.83753 124.942C4.69884 125.038 4.5272 125.076 4.36035 125.047C4.1935 125.018 4.04511 124.924 3.94784 124.786C3.85057 124.648 3.81237 124.478 3.84165 124.312C3.87093 124.146 3.9653 123.998 4.10398 123.901L179.442 1.57297C180.192 1.05172 181.085 0.771522 182.001 0.770026C182.917 0.768529 183.811 1.0458 184.563 1.5646L361.173 123.9C361.284 123.977 361.368 124.088 361.412 124.216C361.456 124.344 361.458 124.483 361.417 124.612C361.376 124.741 361.295 124.854 361.185 124.934C361.076 125.014 360.943 125.057 360.807 125.057L360.807 125.057Z"
          fill="#3F3D56"
        />
        <path
          d="M14.8555 129.393L182.175 6.05396L350.765 138.169L190.796 232.492L103.947 212.801L14.8555 129.393Z"
          fill="#E6E6E6"
        />
        <path
          d="M111.279 290.751H28.6187C28.1112 290.751 27.6085 290.653 27.1394 290.46C26.6703 290.267 26.2439 289.984 25.8848 289.628C25.5257 289.271 25.2408 288.847 25.0464 288.381C24.852 287.914 24.752 287.415 24.752 286.91C24.752 286.405 24.852 285.905 25.0464 285.439C25.2408 284.972 25.5257 284.549 25.8848 284.192C26.2439 283.835 26.6703 283.552 27.1394 283.36C27.6085 283.167 28.1112 283.068 28.6187 283.069H111.279C111.786 283.068 112.289 283.167 112.758 283.36C113.227 283.552 113.654 283.835 114.013 284.192C114.372 284.549 114.657 284.972 114.851 285.439C115.046 285.905 115.146 286.405 115.146 286.91C115.146 287.415 115.046 287.914 114.851 288.381C114.657 288.847 114.372 289.271 114.013 289.628C113.654 289.984 113.227 290.267 112.758 290.46C112.289 290.653 111.786 290.751 111.279 290.751Z"
          fill={Colors['color-primary']}
        />
        <path
          d="M58.2754 274.236H28.6187C28.1112 274.237 27.6085 274.138 27.1394 273.945C26.6703 273.753 26.2439 273.47 25.8848 273.113C25.5257 272.756 25.2408 272.333 25.0464 271.866C24.852 271.4 24.752 270.9 24.752 270.395C24.752 269.89 24.852 269.391 25.0464 268.924C25.2408 268.458 25.5257 268.034 25.8848 267.677C26.2439 267.321 26.6703 267.038 27.1394 266.845C27.6085 266.652 28.1112 266.554 28.6187 266.554H58.2754C58.7829 266.554 59.2856 266.652 59.7548 266.845C60.2239 267.038 60.6502 267.321 61.0093 267.677C61.3684 268.034 61.6533 268.458 61.8477 268.924C62.0421 269.391 62.1422 269.89 62.1422 270.395C62.1422 270.9 62.0421 271.4 61.8477 271.866C61.6533 272.333 61.3684 272.756 61.0093 273.113C60.6502 273.47 60.2239 273.753 59.7548 273.945C59.2856 274.138 58.7829 274.237 58.2754 274.236Z"
          fill={Colors['color-primary']}
        />
        <path
          d="M184.529 198.091C183.901 198.092 183.279 197.971 182.697 197.734L79.5059 155.147V21.8414C79.5072 20.6627 79.9786 19.5326 80.8167 18.699C81.6547 17.8655 82.7909 17.3966 83.976 17.3953H281.941C283.126 17.3966 284.262 17.8655 285.1 18.699C285.938 19.5326 286.41 20.6627 286.411 21.8414V155.24L286.217 155.322L186.417 197.713C185.819 197.963 185.178 198.092 184.529 198.091Z"
          fill="white"
        />
        <path
          d="M184.528 198.408C183.858 198.409 183.195 198.28 182.575 198.028L79.1855 155.359V21.8414C79.187 20.5784 79.6921 19.3676 80.5899 18.4745C81.4878 17.5814 82.7052 17.0791 83.975 17.0776H281.94C283.21 17.0791 284.427 17.5814 285.325 18.4745C286.223 19.3676 286.728 20.5784 286.729 21.8414V155.45L186.541 198.005C185.904 198.272 185.22 198.409 184.528 198.408ZM80.4627 154.51L183.062 196.854C184.019 197.24 185.09 197.234 186.043 196.836L285.452 154.611V21.8414C285.451 20.9152 285.081 20.0273 284.422 19.3724C283.764 18.7175 282.871 18.3491 281.94 18.348H83.975C83.0439 18.3491 82.1511 18.7175 81.4927 19.3724C80.8343 20.0273 80.4639 20.9152 80.4628 21.8414L80.4627 154.51Z"
          fill="#3F3D56"
        />
        <path
          d="M359.53 123.786H359.402L286.091 154.922L185.787 197.523C185.395 197.687 184.974 197.773 184.549 197.775C184.124 197.777 183.703 197.696 183.309 197.536L79.8246 154.833L4.71277 123.837L4.59802 123.786H4.47018C3.28501 123.788 2.14877 124.257 1.31073 125.09C0.472697 125.924 0.00131503 127.054 0 128.233V321.324C0.00131672 322.503 0.472697 323.633 1.31073 324.466C2.14877 325.3 3.28502 325.769 4.47018 325.77H359.53C360.715 325.769 361.851 325.3 362.689 324.466C363.527 323.633 363.999 322.503 364 321.324V128.233C363.999 127.054 363.527 125.924 362.689 125.09C361.851 124.257 360.715 123.788 359.53 123.786ZM362.723 321.324C362.722 322.166 362.386 322.974 361.787 323.569C361.189 324.165 360.377 324.499 359.53 324.5H4.47018C3.62345 324.499 2.8115 324.165 2.21277 323.569C1.61405 322.974 1.27753 322.166 1.27719 321.324V128.233C1.27835 127.411 1.59864 126.622 2.17109 126.03C2.74354 125.439 3.52384 125.09 4.34888 125.057L79.8246 156.205L182.818 198.711C183.932 199.162 185.182 199.155 186.292 198.692L286.091 156.301L359.658 125.057C360.481 125.092 361.259 125.442 361.83 126.033C362.401 126.625 362.721 127.413 362.723 128.233V321.324Z"
          fill="#3F3D56"
        />
        <path
          d="M183.497 160.291C179.687 160.298 175.979 159.073 172.93 156.8L172.741 156.659L132.95 126.356C131.107 124.952 129.56 123.2 128.398 121.201C127.236 119.202 126.481 116.995 126.176 114.706C125.872 112.417 126.023 110.091 126.623 107.86C127.222 105.629 128.257 103.537 129.669 101.704C131.081 99.8715 132.842 98.3331 134.852 97.1772C136.862 96.0212 139.081 95.2703 141.382 94.9672C143.684 94.6642 146.022 94.815 148.265 95.4111C150.508 96.0072 152.611 97.0368 154.454 98.4412L180.228 118.098L241.136 39.0917C242.549 37.2592 244.31 35.7214 246.32 34.5661C248.331 33.4108 250.55 32.6607 252.851 32.3585C255.153 32.0563 257.492 32.208 259.735 32.8049C261.977 33.4018 264.08 34.4322 265.922 35.8374L265.543 36.3488L265.932 35.8448C269.648 38.6856 272.08 42.8767 272.693 47.4984C273.306 52.1202 272.051 56.7952 269.204 60.4979L197.561 153.429C195.904 155.57 193.774 157.303 191.334 158.493C188.895 159.683 186.213 160.298 183.497 160.291Z"
          fill={Colors['color-primary']}
        />
      </g>
      <defs>
        <clipPath id="clip0_3184_71091">
          <rect
            width="364"
            height="325"
            fill="white"
            transform="translate(0 0.77002)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export { IconAccountVerification };
