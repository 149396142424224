import { createSlice } from '@reduxjs/toolkit';
import { MultiSwap } from 'app/models';
import { RootState } from '../store';
import { getMultiSwaps } from '../thunks';

type InitState = {
  multiSwaps: MultiSwap[];
  status: 'loading' | 'idle' | 'error';
  error: string;
  totalPages: number;
};

const initialState: InitState = {
  multiSwaps: [],
  status: 'loading',
  error: '',
  totalPages: 0,
};

const multiSwaps = createSlice({
  name: 'multiSwaps',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMultiSwaps.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getMultiSwaps.fulfilled, (state, action) => {
        state.multiSwaps = action.payload.data;
        state.totalPages = action.payload.page_data.last_page;
        state.status = 'idle';
      })
      .addCase(getMultiSwaps.rejected, (state, action) => {
        state.multiSwaps = [];
        state.error = action.payload as string;
        state.status = 'error';
      });
  },
});

export default multiSwaps.reducer;

export const selectMultiSwaps = (state: RootState) =>
  state.multiSwaps.multiSwaps;
export const selectMultiSwapsStatus = (state: RootState) =>
  state.multiSwaps.status;
export const selectMultiSwapsError = (state: RootState) =>
  state.multiSwaps.error;
