import { createSlice } from '@reduxjs/toolkit';
import { LoadingStatus } from 'app/util/enums';
import {SwapsResponse} from 'app/util/responses';
import { RootState } from '../store';
import { getSwaps } from '../thunks/walletSwaps.thunks';

type InitState = {
  walletSwaps: SwapsResponse;
  status: LoadingStatus;
};

const initialState: InitState = {
  walletSwaps: {
    data: [],
    page_data: {
      current_page: 1,
      last_page: 1,
    },
  },
  status: LoadingStatus.IDLE,
};

const walletSwaps = createSlice({
  name: 'walletSwaps',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSwaps.pending, (state) => {
        state.status = LoadingStatus.PENDING;
      })
      .addCase(getSwaps.fulfilled, (state, action) => {
        state.walletSwaps = action.payload;
        state.status = LoadingStatus.FULFILLED;
      })
      .addCase(getSwaps.rejected, (state) => {
        state.status = LoadingStatus.REJECTED;
      });
  },
});

export default walletSwaps.reducer;

export const selectWalletTotalSwaps = (state: RootState) =>
    state.walletSwaps.walletSwaps.page_data.last_page;

export const selectWalletSwaps = (state: RootState) =>
  state.walletSwaps.walletSwaps;
export const selectWalletSwapsStatus = (state: RootState) =>
  state.walletSwaps.walletSwaps;
