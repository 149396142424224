import { logEvent } from 'firebase/analytics';
import { analytics } from 'app/config';

export const logPageVisited = (name: string) => {
  _logEvent('page_visited', { name });
};

export const logSearch = (value: string) => {
  _logEvent('search', { value });
};

export const logFavorite = () => {
  _logEvent('favorite_added');
};

export const logButtonClick = (name: string) => {
  _logEvent('button_click', { name });
};

export const logPlotClick = (name: string, page: string) => {
  _logEvent('plot_click', { name, page });
};

export const logFilterChange = (name: string, value: string) => {
  _logEvent(`${name}_filter_change`, { value });
};

const _logEvent = (event: string, parameters?: Record<string, unknown>) => {
  logEvent(analytics, event, parameters);
};
