import styles from './SwapDetails.module.scss';

interface SwapDetailsProps {
  name: string | JSX.Element;
  logo: string;
  time?: Date;
}

const SwapDetails = ({ name, logo, time }: SwapDetailsProps): JSX.Element => {
  return (
    <div className={styles['swap-details-wrapper']}>
      <img src={logo} width={'40'} height={'40'} alt={`${name} logo`} />
      {time ? (
        <div className={styles['swap-details']}>
          <span>{name}</span>
          <span>{time?.toLocaleTimeString()}</span>
        </div>
      ) : (
        <div>{name}</div>
      )}
    </div>
  );
};

export { SwapDetails };
