import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchMultiSwaps } from 'app/api';
import { MultiSwapsRequest } from 'app/util/requests';
import { IFail } from 'app/util/responses';
import { AxiosError } from 'axios';

enum MultiSwapsThunks {
  GET_MULTI_SWAPS = 'getMultiSwaps',
}

export const getMultiSwaps = createAsyncThunk(
  MultiSwapsThunks.GET_MULTI_SWAPS,
  async (params: MultiSwapsRequest, { rejectWithValue }) => {
    try {
      const response = await fetchMultiSwaps(params);
      return response;
    } catch (e) {
      const error = e as AxiosError<IFail>;
      return rejectWithValue(
        error.response?.data.detail || 'Unrecognized error happened'
      );
    }
  }
);
