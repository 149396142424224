import { dataHttp } from 'app/util/http';
import {
  RouterResponse,
  TokenLogoResponse,
  TokenLogosResponse,
} from 'app/util/responses';

const api = dataHttp;

export function getTokenLogo(
  contractAddress: string
): Promise<TokenLogoResponse> {
  return api.get(`/v1/tokens/${contractAddress}`);
}

export function getTokenLogos(
  addresses: string[]
): Promise<TokenLogosResponse> {
  return api.post('/v1/tokens', addresses);
}

export function getRouter(contractAddress: string): Promise<RouterResponse> {
  return api.get(`/v1/routers/${contractAddress}`);
}

export function getRouters(
  contractAddresses: string[]
): Promise<RouterResponse[]> {
  return api.post('/v1/routers', contractAddresses);
}
