import { authHttp } from '../util/http';
import {
  ChangePasswordRequest,
  ConfirmSignupRequest,
  ForgotPasswordRequest,
  LoginRequest,
  ResendConfirmationCodeRequest,
  ResetPasswordRequest,
  SignupRequest,
} from 'app/util/requests';
import {
  ConfirmSignupResponse,
  ForgotPasswordResponse,
  LoginResponse,
  ResetPasswordResponse,
  SignupResponse,
} from 'app/util/responses';
import { getFromLocalStorage } from 'app/util/helper-functions';
import { PriceIds } from 'app/util/interfaces';

const client = authHttp;

export function loginUser(payload: LoginRequest): Promise<LoginResponse> {
  return client.post('/login', payload);
}

export function signupUser(payload: SignupRequest): Promise<SignupResponse> {
  return client.post('/sign_up', payload);
}

export function resendConfirmEmailCode(
  payload: ResendConfirmationCodeRequest
): Promise<ConfirmSignupResponse> {
  return client.post('/resend_confirm_code', payload);
}

export function confirmSignupUser(
  payload: ConfirmSignupRequest
): Promise<ConfirmSignupResponse> {
  return client.post('/confirm_sign_up', payload);
}

export function forgotUserPassword(
  payload: ForgotPasswordRequest
): Promise<ForgotPasswordResponse> {
  return client.post('/forgot_password', payload);
}

export function resetUserPassword(
  payload: ResetPasswordRequest
): Promise<ResetPasswordResponse> {
  return client.post('/confirm_forgot_password', payload);
}

export function changeUserPassword(payload: ChangePasswordRequest) {
  return client.post('/change_password', payload);
}

export function refreshToken(): Promise<LoginResponse> {
  return client.post('/refresh', null, {
    headers: {
      'refresh-token': getFromLocalStorage('refreshToken'),
    },
  });
}

export function getPriceIds(): Promise<PriceIds> {
  return client.get('/price-ids');
}
