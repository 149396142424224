import { Component, ReactNode } from 'react';

import styles from './ErrorBoundary.module.scss';

interface ErrorInfo {
  componentStack: string;
}

interface ErrorBoundaryProps {
  children: ReactNode;
}

class ErrorBoundary extends Component {
  state: {
    hasError: boolean;
    error: Error | null;
    errorInfo: ErrorInfo | null;
  };
  children: ReactNode | null = null;

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
    // eslint-disable-next-line react/prop-types
    this.children = props.children;
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState((prevState) => ({ ...prevState, error, errorInfo }));
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles['error-boundary']}>
          <h1>Something went wrong!</h1>
          <details className={styles['error-details']}>
            <summary>{this.state.error?.toString()}</summary>
            <p>{this.state.errorInfo?.componentStack}</p>
          </details>
        </div>
      );
    }

    return this.children;
  }
}
export { ErrorBoundary };
