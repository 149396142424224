import { Tooltip, TooltipPosition, TooltipSize } from '../Tooltip/Tooltip';
import styles from './TableHeader.module.scss';

interface TableHeaderProps {
  label: string;
  description: string;
  size: TooltipSize;
  position: TooltipPosition;
}

export const TableHeader = ({
  label,
  description,
  size,
  position,
}: TableHeaderProps) => {
  return (
    <th>
      <div className={styles['th-content']}>
        <span>{label}</span>
        {description && (
          <Tooltip text={description} size={size} position={position} />
        )}
      </div>
    </th>
  );
};
