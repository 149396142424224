import { createAsyncThunk } from '@reduxjs/toolkit';
import { getBuys } from 'app/api';
import { SwapsRequest } from 'app/util/requests';
import { IFail } from 'app/util/responses';
import { AxiosError } from 'axios';

enum BuyThunks {
  GET_ALL = 'buys/getAll',
}

export const fetchAllBuys = createAsyncThunk(
  BuyThunks.GET_ALL,
  async (params: SwapsRequest, { rejectWithValue }) => {
    try {
      const response = await getBuys(params);
      return response;
    } catch (e) {
      const error = e as AxiosError<IFail>;
      return rejectWithValue(
        error.response?.data.detail || 'Unrecognized error happened'
      );
    }
  }
);
