import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSimulationId } from 'app/api';
import { SimRequest } from 'app/util/requests';
import { AuthFails } from 'app/util/responses/auth-fails.response';
import { AxiosError } from 'axios';

enum SimulatorThunks {
  ID = 'simulator/startSimulation',
  DEF_ID = 'simulator/startDefaultSimulation',
}

export const startSimulation = createAsyncThunk(
  SimulatorThunks.ID,
  async (params: SimRequest, { rejectWithValue }) => {
    try {
      const response = await getSimulationId(params);
      if (
        response === 'No swaps found in favorite list' ||
        response === 'failure' ||
        response === 'Simualtion ID not found' ||
        response === 'User not in DB or has no simulation data' ||
        response === 'Collection does not exist'
      ) {
        return rejectWithValue(response);
      } else {
        return response;
      }
    } catch (err) {
      const error = err as AxiosError<AuthFails>;
      return rejectWithValue(error?.response?.data?.auth_fail);
    }
  }
);
