import styles from './IconButton.module.scss';
import { FC } from 'react';

type IconButtonProps = {
  children: JSX.Element;
  onClick: () => void;
};

const IconButton: FC<IconButtonProps> = ({ children, onClick }) => {
  return (
    <button className={styles['icon-button']} onClick={onClick}>
      {children}
    </button>
  );
};

export { IconButton };
