import { useEffect, useState } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { sharedActions, formActions, isReset } from 'app/store/slices';
import { Tooltip } from 'app/components';
import styles from './QuadStateCheckbox.module.scss';
import { logFilterChange } from 'services';
import { useAppSelector } from 'app/store';

interface QuadStateCheckboxProps {
  label: string;
  name: string;
  tooltipText: string;
  reset: (
    values?: Record<string, string>,
    options?: Record<string, boolean>
  ) => void;
  register: UseFormRegister<{ [name: string]: string }>;
  getValue?: (value: string) => void;
  isReachable: boolean;
  defaultValue?: 'include' | 'exclude' | 'any' | 'exclude_not_authorized';
}

const QuadStateCheckbox = ({
  label,
  name,
  tooltipText,
  register,
  isReachable,
  defaultValue,
}: QuadStateCheckboxProps): JSX.Element => {
  const { addToast } = sharedActions;
  const [value, setValue] = useState<
    'any' | 'include' | 'exclude' | 'exclude_not_authorized'
  >(defaultValue || 'any');
  const dispatch = useDispatch();
  const isFormReseted = useAppSelector(isReset);

  useEffect(() => {
    if (isFormReseted) {
      setValue(defaultValue || 'any');
    }
  }, [isFormReseted]);

  const handleClick = () => {
    if (isReachable) {
      dispatch(formActions.setReset(false));
      setValue((prevValue) => {
        if (prevValue === 'any') {
          logFilterChange(name, 'include');
          return 'include';
        } else if (prevValue === 'include') {
          logFilterChange(name, 'exclude');
          return 'exclude';
        } else {
          logFilterChange(name, 'any');
          return 'any';
        }
      });
      dispatch(formActions.setReset(false));
    } else {
      dispatch(
        addToast({
          title: 'Info',
          description:
            'We are sorry 🙁! This filter is not available with your subscription',
          id: Date.now(),
        })
      );
    }
  };

  return (
    <div className={styles['checkbox-container']}>
      <button
        value={value}
        {...register(name)}
        type="button"
        className={`${styles[`item-${value}`]} ${styles['checkbox']} ${
          !isReachable ? styles['def-cursor'] : ''
        }`}
        onClick={handleClick}
      >
        {(value === 'exclude' || value === 'exclude_not_authorized') && '✖'}
        {value === 'include' && '✔'}
      </button>
      <span className={styles['label']}>{label}</span>
      <div className={styles['tooltip-wrapper']}>
        <Tooltip size="small" position="top-right" text={tooltipText} />
      </div>
    </div>
  );
};

export { QuadStateCheckbox };
