import { createSlice } from '@reduxjs/toolkit';

import { Favorite } from 'app/models';
import { LoadingStatus } from 'app/util/enums';
import { RootState } from '../store';
import {
  addFavoriteList,
  addWalletToFavoriteList,
  fetchAllFavorites,
  removeFavoriteList,
  removeWalletFromFavoriteList,
} from '../thunks';

export interface FavoriteState {
  items: Favorite[];
  itemsStatus: LoadingStatus;
  error: string | null;
}

const initialState: FavoriteState = {
  items: [],
  itemsStatus: LoadingStatus.IDLE,
  error: null,
};

export const favoriteSlice = createSlice({
  name: 'favorite',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllFavorites.pending, (state) => {
        state.itemsStatus = LoadingStatus.PENDING;
      })
      .addCase(fetchAllFavorites.fulfilled, (state, action) => {
        state.items = action.payload.favorites;
        state.itemsStatus = LoadingStatus.FULFILLED;
      })
      .addCase(fetchAllFavorites.rejected, (state, action) => {
        state.itemsStatus = LoadingStatus.REJECTED;
        state.error = action.payload as string;
      })
      .addCase(removeFavoriteList.pending, (state) => {
        state.itemsStatus = LoadingStatus.PENDING;
      })
      .addCase(removeFavoriteList.fulfilled, (state) => {
        state.itemsStatus = LoadingStatus.FULFILLED;
      })
      .addCase(removeFavoriteList.rejected, (state, action) => {
        state.itemsStatus = LoadingStatus.REJECTED;
        state.error = action.payload as string;
      })
      .addCase(addFavoriteList.pending, (state) => {
        state.itemsStatus = LoadingStatus.PENDING;
      })
      .addCase(addFavoriteList.fulfilled, (state) => {
        state.itemsStatus = LoadingStatus.FULFILLED;
      })
      .addCase(addFavoriteList.rejected, (state, action) => {
        state.itemsStatus = LoadingStatus.REJECTED;
        state.error = action.payload as string;
      })
      .addCase(addWalletToFavoriteList.pending, (state) => {
        state.itemsStatus = LoadingStatus.PENDING;
      })
      .addCase(addWalletToFavoriteList.fulfilled, (state) => {
        state.itemsStatus = LoadingStatus.FULFILLED;
      })
      .addCase(addWalletToFavoriteList.rejected, (state, action) => {
        state.itemsStatus = LoadingStatus.REJECTED;
        state.error = action.payload as string;
      })
      .addCase(removeWalletFromFavoriteList.pending, (state) => {
        state.itemsStatus = LoadingStatus.PENDING;
      })
      .addCase(removeWalletFromFavoriteList.fulfilled, (state) => {
        state.itemsStatus = LoadingStatus.FULFILLED;
      })
      .addCase(removeWalletFromFavoriteList.rejected, (state, action) => {
        state.itemsStatus = LoadingStatus.REJECTED;
        state.error = action.payload as string;
      });
  },
});

export const favoriteActions = favoriteSlice.actions;

export const selectFavorites = (state: RootState): Favorite[] => {
  //Dirty bugfix, we should check if server returns "USER HAS NO FAVORITES" instead of doing this
  if (undefined === state.favorite.items) return []
  return state.favorite.items;
}
export const selectFavoriteStatus = (state: RootState): LoadingStatus =>
  state.favorite.itemsStatus;

const favoriteReducer = favoriteSlice.reducer;

export default favoriteReducer;
