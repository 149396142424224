import { IconProps } from 'app/util/interfaces';

const IconChevron = ({ cssClasses }: IconProps): JSX.Element => {
  return (
    <svg
      className={cssClasses}
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.76608 6.81829L1.25451 13.0885L0.342896 12.2107L5.94284 6.81829L0.342896 1.42593L1.25451 0.548102L7.76608 6.81829Z"
        fill="#8E8D8D"
      />
    </svg>
  );
};

export { IconChevron };
