import { SwapsByIdsRequest, SwapsRequest } from 'app/util/requests';
import { SwapsResponse, SwapsByIdsResponse } from 'app/util/responses';
import { dataHttp } from '../util/http';

const api = dataHttp;

export function getSwaps(params: SwapsRequest): Promise<SwapsResponse> {
  return api.get('/v1/swaps', { params });
}

export function getSwapsByIds({
  relatedSwapIds,
  snapshot,
}: SwapsByIdsRequest): Promise<SwapsByIdsResponse> {
  return api.post('/v1/swaps', relatedSwapIds, { params: { snapshot } });
}
