import { createAsyncThunk } from '@reduxjs/toolkit';
import { getOngoingWeekMetadata, getAllWeeklyMetadata } from 'app/api';

enum MetadataThunks {
  ONGOING = 'metadata/getCurrent',
  ALL_WEEKLY = 'metadata/getWeekly',
}

export const fetchOngoingWeekMetadata = createAsyncThunk(
  MetadataThunks.ONGOING,
  () => {
    return getOngoingWeekMetadata();
  }
);

export const fetchAllWeeklyMetadata = createAsyncThunk(
  MetadataThunks.ALL_WEEKLY,
  () => {
    return getAllWeeklyMetadata();
  }
);
