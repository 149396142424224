import { FilterValues } from 'app/util/interfaces';
import { WalletAndSnapshot } from 'app/util/requests';
import { PlotResponse } from 'app/util/responses';
import { dataHttp } from '../util/http';

const client = dataHttp;

export function getWalletsBubblePlot(
  params?: FilterValues
): Promise<PlotResponse> {
  return client.get('/v1/plots/trade_bubble', { params });
}

export function getSimulatorBubblePlot(id: string): Promise<PlotResponse> {
  return client.get(`/v1/simulation/plots/profit_bubbles?sim_id=${id}`);
}

export function getWalletsRoiPlot(
  params?: FilterValues & { snapshot?: string }
): Promise<PlotResponse> {
  return client.get('/v1/plots/roi_line', { params });
}

export function getSimulatorRoiPlot(id: string): Promise<PlotResponse> {
  return client.get(`/v1/simulation/plots/profit_line?sim_id=${id}`);
}

export function postMultiSimulatorRoiPlot(
  arr: string[]
): Promise<PlotResponse> {
  return client.post(`/v1/simulation/plots/multi_simulation_roi_line`, arr);
}

export function getSimulatorBreakdownPlot(id: string): Promise<PlotResponse> {
  return client.get(`/v1/simulation/plots/sunburst_trades?sim_id=${id}`);
}

export function getProfitHierarchyPlot(
  params?: FilterValues
): Promise<PlotResponse> {
  return client.get('/v1/plots/profit_box_hierachy', { params });
}

export function getWalletBubblePlot({
  walletId,
  snapshot,
}: WalletAndSnapshot): Promise<PlotResponse> {
  const url = snapshot
    ? `/v1/plots/trade_bubble/${walletId}?snapshot=${snapshot}`
    : `/v1/plots/trade_bubble/${walletId}`;
  return client.get(url);
}

export function getWalletRoiPlot({
  walletId,
  snapshot,
}: WalletAndSnapshot): Promise<PlotResponse> {
  const url = snapshot
    ? `/v1/plots/roi_line/${walletId}?snapshot=${snapshot}`
    : `/v1/plots/roi_line/${walletId}`;
  return client.get(url);
}

export function getWalletWinnerTradesPlot({
  walletId,
  snapshot,
}: WalletAndSnapshot): Promise<PlotResponse> {
  const url = snapshot
    ? `/v1/plots/win_pie/${walletId}?snapshot=${snapshot}`
    : `/v1/plots/win_pie/${walletId}`;
  return client.get(url);
}

export function getSimulatorWinPlot(id: string): Promise<PlotResponse> {
  return client.get(`/v1/simulation/plots/win_pie?sim_id=${id}`);
}

export function getWalletWinLosePlot({
  walletId,
  snapshot,
}: WalletAndSnapshot): Promise<PlotResponse> {
  const url = snapshot
    ? `/v1/plots/win_loose_pie/${walletId}?snapshot=${snapshot}`
    : `/v1/plots/win_loose_pie/${walletId}`;
  return client.get(url);
}

export function getSimulatorWinLosePlot(id: string): Promise<PlotResponse> {
  return client.get(`/v1/simulation/plots/win_and_loose_pie?sim_id=${id}`);
}

export function getWalletLosePlot({
  walletId,
  snapshot,
}: WalletAndSnapshot): Promise<PlotResponse> {
  const url = snapshot
    ? `/v1/plots/loose_pie/${walletId}?snapshot=${snapshot}`
    : `/v1/plots/loose_pie/${walletId}`;
  return client.get(url);
}

export function getSimulatorLoosePlot(id: string): Promise<PlotResponse> {
  return client.get(`/v1/simulation/plots/loose_pie?sim_id=${id}`);
}

export function getSimulatorUnsoldPlot(id: string): Promise<PlotResponse> {
  return client.get(`/v1/simulation/plots/holdings_pie?sim_id=${id}`);
}

export function getWalletDailySwaps({
  walletId,
  snapshot,
}: WalletAndSnapshot): Promise<PlotResponse> {
  const url = snapshot
    ? `/v1/plots/daily_trade_distribution/${walletId}?snapshot=${snapshot}`
    : `/v1/plots/daily_trade_distribution/${walletId}`;
  return client.get(url);
}

export function getWalletHourlySwaps({
  walletId,
  snapshot,
}: WalletAndSnapshot): Promise<PlotResponse> {
  const url = snapshot
    ? `/v1/plots/hourly_trade_distribution/${walletId}?snaphot=${snapshot}`
    : `/v1/plots/hourly_trade_distribution/${walletId}`;
  return client.get(url);
}

export function getWalletAllTimeSwaps({
  walletId,
  snapshot,
}: WalletAndSnapshot): Promise<PlotResponse> {
  const url = snapshot
    ? `/v1/plots/total_trade_distribution/${walletId}?snapshot=${snapshot}`
    : `/v1/plots/total_trade_distribution/${walletId}`;
  return client.get(url);
}

export function getWalletSankeyPlot({
  snapshot,
  walletId,
}: WalletAndSnapshot): Promise<PlotResponse> {
  const url = snapshot
    ? `/v1/plots/sankey_profit_flow/${walletId}?snapshot=${snapshot}`
    : `/v1/plots/sankey_profit_flow/${walletId}`;
  return client.get(url);
}

export function getWalletsSankeyPlot(
  params?: FilterValues
): Promise<PlotResponse> {
  return client.get('/v1/plots/sankey_profit_flow', { params });
}

export function getWalletTokenHoldings({
  walletId,
  snapshot,
}: WalletAndSnapshot): Promise<PlotResponse> {
  const url = snapshot
    ? `/v1/plots/token_holding/${walletId}?snapshot=${snapshot}`
    : `/v1/plots/token_holding/${walletId}`;
  return client.get(url);
}
