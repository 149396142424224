import { useFormContext } from 'react-hook-form';

import { FilterInput } from 'app/components';
import styles from './SwapFilters.module.scss';

const SwapFilters = (): JSX.Element => {
  const methods = useFormContext();
  return (
    <div className={styles['inputs-wrapper']}>
      <div className={styles['input-wrapper']}>
        <FilterInput
          label="Min Swap Profit"
          name="min_swap_profit"
          placeholder="any"
          {...methods}
        />
        <p className={styles['error-message']}>
          {methods.formState.errors.min_swap_profit?.message
            ? 'Invalid value!'
            : ''}
        </p>
      </div>
      <div className={styles['input-wrapper']}>
        <FilterInput
          label="Min Swap Size"
          placeholder="any"
          name="swap_min_size"
          {...methods}
        />
        <p className={styles['error-message']}>
          {methods.formState.errors.swap_min_size?.message
            ? 'Invalid value!'
            : ''}
        </p>
      </div>
      <div className={styles['input-wrapper']}>
        <FilterInput
          label="Max Swap Profit"
          placeholder="any"
          name="max_swap_profit"
          {...methods}
        />
        <p className={styles['error-message']}>
          {methods.formState.errors.max_swap_profit?.message
            ? 'Invalid value!'
            : ''}
        </p>
      </div>
      <div className={styles['input-wrapper']}>
        <FilterInput
          label="Max Swap size"
          placeholder="any"
          name="swap_max_size"
          {...methods}
        />
        <p className={styles['error-message']}>
          {methods.formState.errors.swap_max_size?.message
            ? 'Invalid value!'
            : ''}
        </p>
      </div>
    </div>
  );
};

export { SwapFilters };
