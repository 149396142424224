import { MouseEventHandler, ReactNode } from 'react';
import styles from './Button.module.scss';

interface ButtonProps {
  cssClasses?: string;
  children?: ReactNode;
  disabled?: boolean;
  form?: string;
  name?: string;
  type?: 'button' | 'submit' | 'reset';
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const Button = ({
  children,
  onClick,
  cssClasses = 'background-black-2',
  disabled = false,
  form = '',
  name = '',
  type = 'button',
}: ButtonProps): JSX.Element => {
  return (
    <button
      className={`${styles['button']}  ${cssClasses}`}
      disabled={disabled}
      form={form}
      name={name}
      type={type}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export { Button };
