import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  deleteFavoriteList,
  deleteWalletFromFavoriteList,
  getFavorites,
  postFavoriteList,
  postWalletToFavoriteList,
} from 'app/api';
import {
  AddFavoriteListRequest,
  AddWalletToFavoriteListRequest,
  DeleteFavoriteListRequest,
  DeleteWalletFromFavoriteListRequest,
  FavoritesRequest,
} from 'app/util/requests';
import { AuthFails, IFail } from 'app/util/responses';
import { AxiosError } from 'axios';
import { sharedActions } from '../slices';

enum FavoriteThunks {
  GET_ALL = 'favorites/getAll',
  DELETE_LIST = 'favorites/deleteList',
  ADD_LIST = 'favorites/addList',
  ADD_WALLET_TO_LIST = 'favorites/addWalletToList',
  DELETE_WALLET_FROM_LIST = 'favorites/deleteWalletFromList',
}

export const fetchAllFavorites = createAsyncThunk(
  FavoriteThunks.GET_ALL,
  (params: FavoritesRequest) => {
    return getFavorites(params);
  }
);

export const removeFavoriteList = createAsyncThunk(
  FavoriteThunks.DELETE_LIST,
  async (params: DeleteFavoriteListRequest, { dispatch }) => {
    try {
      const response = await deleteFavoriteList(params);
      return response;
    } catch (e) {
      const error = e as AxiosError<IFail>;
      dispatch(
        sharedActions.addToast({
          title: 'Danger',
          description:
            error.response?.data.detail || 'Unrecognized error happened',
          id: Date.now(),
        })
      );
    }
  }
);

export const addFavoriteList = createAsyncThunk(
  FavoriteThunks.ADD_LIST,
  async (params: AddFavoriteListRequest, { dispatch, rejectWithValue }) => {
    try {
      const res = await postFavoriteList(params);
      if (res === 'Collection with this name already exists') {
        dispatch(
          sharedActions.addToast({
            title: 'Danger',
            description: 'Collection with this name already exists',
            id: Date.now(),
          })
        );
        return rejectWithValue('');
      }
      return res;
    } catch (e) {
      const error = e as AxiosError<AuthFails>;
      if (error.response?.data.auth_fail) {
        dispatch(
          sharedActions.addToast({
            title: 'Danger',
            description: error.response?.data.auth_fail,
            id: Date.now(),
          })
        );
      }
      rejectWithValue('');
    }
  }
);

export const addWalletToFavoriteList = createAsyncThunk(
  FavoriteThunks.ADD_WALLET_TO_LIST,
  async (
    params: AddWalletToFavoriteListRequest,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await postWalletToFavoriteList(params);
      return res;
    } catch (e) {
      const error = e as AxiosError<AuthFails>;
      if (error.response?.data.auth_fail) {
        dispatch(
          sharedActions.addToast({
            title: 'Danger',
            description:
              error.response?.data.auth_fail || 'Unrecognized error happened',
            id: Date.now(),
          })
        );
      }
      rejectWithValue('');
    }
  }
);

export const removeWalletFromFavoriteList = createAsyncThunk(
  FavoriteThunks.DELETE_WALLET_FROM_LIST,
  async (params: DeleteWalletFromFavoriteListRequest, { dispatch }) => {
    try {
      const response = await deleteWalletFromFavoriteList(params);
      return response;
    } catch (e) {
      const error = e as AxiosError<IFail>;
      dispatch(
        sharedActions.addToast({
          title: 'Danger',
          description:
            error.response?.data.detail || 'Unrecognized error happen',
          id: Date.now(),
        })
      );
    }
  }
);
