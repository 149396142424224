import { SearchInputProps } from 'app/util/interfaces/search-input-props.interface';
import { FC } from 'react';
import useWindowDimensions from 'services/hooks/useWindowDimensions';
import { SearchInputDesktop } from './SearchInputDesktop';
import { SearchInputMobile } from './SearchInputMobile';

const SearchInput: FC<SearchInputProps> = (props) => {
  const { width } = useWindowDimensions();
  return width > 600 ? (
    <SearchInputDesktop {...props}></SearchInputDesktop>
  ) : (
    <SearchInputMobile {...props}></SearchInputMobile>
  );
};

export { SearchInput };
