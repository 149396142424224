import { useAppDispatch, useAppSelector } from 'app/store';
import {
  selectActiveFilter,
  selectOngoingWeekMetadata,
  selectAllWeeklyMetadata,
  setActiveFilter,
  selectActiveMetadata,
} from 'app/store/slices';
import { extractNumberFromString } from 'app/util/helper-functions';
import { useRef, useState } from 'react';
import { Card } from './Card';
import styles from './MetadataBox.module.scss';
import { useClickAwayListener } from 'services/hooks/useClickAwayListener';

export const MetadataBox = () => {
  const dispatch = useAppDispatch();
  const activeMetadata = useAppSelector(selectActiveMetadata);
  const ongoingWeekMetadata = useAppSelector(selectOngoingWeekMetadata);
  const allWeeklyMetadata = useAppSelector(selectAllWeeklyMetadata);
  const activeFilter = useAppSelector(selectActiveFilter);
  const [isBodyOpen, setIsBodyOpen] = useState(false);
  const metadataRef = useRef(null);

  useClickAwayListener(metadataRef, () => {
    setIsBodyOpen(false);
  });

  const filters: ('All snapshots' | 'Year 2023' | 'Year 2022' | 'Year 2021')[] =
    ['All snapshots', 'Year 2023', 'Year 2022', 'Year 2021'];

  const onFilterClick = (
    filter: 'All snapshots' | 'Year 2023' | 'Year 2022' | 'Year 2021'
  ) => {
    dispatch(setActiveFilter(filter));
  };

  return (
    <div className={styles['metadata']} ref={metadataRef}>
      <div className={styles['metadata-head']}>
        <Card
          metadata={activeMetadata || ongoingWeekMetadata}
          selectable={false}
          isBodyOpen={isBodyOpen}
          setIsBodyOpen={setIsBodyOpen}
        />
      </div>
      {isBodyOpen && (
        <div className={styles['metadata-body']}>
          <div className={styles['metadata-body-col']}>
            {allWeeklyMetadata
              .filter((weeklyMetadata) =>
                activeFilter === 'All snapshots'
                  ? true
                  : weeklyMetadata.snapshot_year_label ===
                    extractNumberFromString(activeFilter)
              )
              .map((weeklyMetadata) => (
                <Card
                  metadata={weeklyMetadata}
                  key={weeklyMetadata.snapshot_id}
                  setIsBodyOpen={setIsBodyOpen}
                />
              ))}
          </div>
          <div className={styles['metadata-body-col']}>
            <ul className={styles['filters']}>
              {filters.map((filter) => {
                const className = filter.includes(String(activeFilter))
                  ? `${styles.filter} ${styles['filter-active']}`
                  : styles.filter;

                return (
                  <li key={filter}>
                    <button
                      onClick={() => onFilterClick(filter)}
                      className={className}
                    >
                      {filter}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};
