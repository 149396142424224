import React, { FC, useState } from 'react';
import {
  IconButton,
  IconCalendar,
  IconExpandLess,
  IconExpandMore,
  IconTransaction,
  IconWallet,
} from 'app/components';
import styles from './Card.module.scss';
import { Metadata } from 'app/util/interfaces';
import { useAppDispatch, useAppSelector } from 'app/store';
import {
  setIsMenuOpen,
  selectActiveMetadata,
  setActiveMetadata,
} from 'app/store/slices';
import { useSearchParams } from 'react-router-dom';
import {
  getObjectFromSearchParams,
  removeSnapshotFromSearchParams,
} from 'app/util/helper-functions';

type CardProps = {
  metadata: Metadata | null;
  selectable?: boolean;
  isBodyOpen?: boolean;
  setIsBodyOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const Card: FC<CardProps> = ({
  metadata,
  selectable = true,
  isBodyOpen,
  setIsBodyOpen,
}) => {
  const dispatch = useAppDispatch();
  const activeMetadata = useAppSelector(selectActiveMetadata);
  // eslint-disable-next-line
  const [_, setSearchParams] = useSearchParams();
  const [isShowFullDate, setIsShowFullDate] = useState(false);

  const toggleBodyOpening = () => {
    setIsBodyOpen((isBodyOpen) => !isBodyOpen);
  };

  const onClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (!metadata?.snapshot_id) {
      return;
    }

    if (!selectable) {
      return;
    }

    if (metadata.snapshot_id === activeMetadata?.snapshot_id) {
      setSearchParams((prev) => removeSnapshotFromSearchParams(prev));
      dispatch(setActiveMetadata(null));
    } else {
      setSearchParams((prev) => ({
        ...getObjectFromSearchParams(prev),
        snapshot: metadata.snapshot_id,
      }));
      dispatch(setActiveMetadata(metadata));
    }

    setTimeout(() => {
      if (setIsBodyOpen) {
        setIsBodyOpen(false);
      }
      dispatch(setIsMenuOpen(false));
    }, 300);
  };

  const mouseOverHandler = () => {
    setIsShowFullDate(true);
  };

  const mouseOutHandler = () => {
    setIsShowFullDate(false);
  };

  const showPeriod = (): string | undefined => {
    if (!metadata) {
      return;
    }

    const showFullDate = (startTime: number, endTime: number): string => {
      return `${new Date(startTime).toLocaleDateString()} - ${new Date(
        endTime
      ).toLocaleDateString()}`;
    };
    const showWeek = (year: number, week: number): string => {
      return `${year} Week ${week}`;
    };

    if (!selectable) {
      // means top card
      if (!activeMetadata) {
        return 'Last 7 Days';
      }

      return showWeek(
        metadata.snapshot_year_label,
        metadata.snapshot_week_label
      );
    }

    // body cards
    if (isShowFullDate) {
      return showFullDate(metadata.data_start_time, metadata.data_end_time);
    }
    return showWeek(metadata.snapshot_year_label, metadata.snapshot_week_label);
  };

  return (
    metadata && (
      <button
        className={[
          selectable
            ? activeMetadata?.snapshot_id === metadata.snapshot_id
              ? `${styles['info-box']} ${styles['info-box-selected']}`
              : styles['info-box']
            : `${styles['info-box']} ${styles['def-cursor']}`,
        ].join(' ')}
        onClick={onClick}
      >
        <div className={styles['info-box-col']}>
          <div className={styles['info-box-item']}>
            <IconWallet />
            <span>{metadata.num_wallets.toLocaleString()}</span>
          </div>

          <div className={styles['info-box-item']}>
            <IconTransaction />
            <span>{metadata.num_transactions.toLocaleString()}</span>
          </div>

          <div
            className={styles['info-box-item']}
            onFocus={mouseOverHandler}
            onMouseOver={mouseOverHandler}
            onMouseOut={mouseOutHandler}
            onBlur={mouseOutHandler}
          >
            <IconCalendar />
            {showPeriod()}
          </div>
        </div>
        <div className={styles['info-box-col']}>
          {!selectable && (
            <IconButton onClick={toggleBodyOpening}>
              {isBodyOpen ? <IconExpandLess /> : <IconExpandMore />}
            </IconButton>
          )}
        </div>
      </button>
    )
  );
};

export { Card };
