import { createSlice } from '@reduxjs/toolkit';
import { LoadingStatus } from 'app/util/enums';
import { TokenLogo } from 'app/util/interfaces';
import { RootState } from '../store';
import { fetchTokenLogo, fetchTokenLogos } from '../thunks';

export interface TokenLogos {
  [key: string]: TokenLogo;
}

interface TokenState {
  logos: TokenLogos;
  logosStatus: LoadingStatus;
}

const initialState: TokenState = {
  logos: {},
  logosStatus: LoadingStatus.IDLE,
};

export const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTokenLogo.fulfilled, (state, action) => {
        state.logos[action.payload.contract_address] = {
          image: { ...action.payload.image },
        };
      })
      .addCase(fetchTokenLogos.pending, (state) => {
        state.logosStatus = LoadingStatus.PENDING;
      })
      .addCase(fetchTokenLogos.fulfilled, (state, action) => {
        action.payload.forEach((token) => {
          state.logos[token.contract_address] = { image: { ...token.image } };
        });
        state.logosStatus = LoadingStatus.FULFILLED;
      });
  },
});

export const tokenActions = tokenSlice.actions;

export const selectLogos = (state: RootState): TokenLogos => state.token.logos;
export const selectLogosStatus = (state: RootState): LoadingStatus =>
  state.token.logosStatus;

const tokenReducer = tokenSlice.reducer;
export default tokenReducer;
