import { SimulatorData, SimulatorMetricsData } from 'app/models';
import { SimulationFails } from 'app/util/responses';
import { dataHttp } from '../util/http';
import { SimulatorParams } from 'app/util/interfaces';

const api = dataHttp;

export function getSimulationTransactions(
  id: string
): Promise<SimulatorData[]> {
  return api.get(`/v1/simulation/data/transactions?simulation_id=${id}`);
}

export function getSimulationMetrics(
  id: string
): Promise<SimulatorMetricsData> {
  return api.get(`/v1/simulation/data/metrics?simulation_id=${id}`);
}

export function getSimulationId(
  params: SimulatorParams
): Promise<string | SimulationFails> {
  return api.get('/v1/simulation/simulate', { params });
}
