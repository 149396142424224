import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSwaps } from 'app/api';
import { SwapsRequest } from 'app/util/requests';
import { IFail } from 'app/util/responses';
import { AxiosError } from 'axios';

enum SwapThunks {
  GET_DATA = 'swaps/getSwapsData',
}

export const getSwapsData = createAsyncThunk(
  SwapThunks.GET_DATA,
  async (params: SwapsRequest, { rejectWithValue }) => {
    try {
      const response = await getSwaps(params);
      return response;
    } catch (e) {
      const error = e as AxiosError<IFail>;
      return rejectWithValue(
        error.response?.data.detail || 'Unrecognized error happened'
      );
    }
  }
);
