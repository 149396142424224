import styles from './RemainingFilterCredits.module.scss';
import React, { FC } from 'react';
import { useAppSelector } from 'app/store';
import { selectCredits } from 'app/store/slices';
import { Tooltip } from '../Tooltip/Tooltip';

const RemainingFilterCredits: FC = () => {
  const credits = useAppSelector(selectCredits);

  return (
    <div className={styles['remaining-filter-credits']}>
      <p>
        <span>Remaining filter credits</span>&nbsp;
        <Tooltip
          size="medium"
          text="Each subscription provides a certain amount of filter usage"
          position="bottom-right"
        ></Tooltip>
      </p>
      <p>
        {credits.filter_credit} / {credits.max_filter_credit}
      </p>
    </div>
  );
};

export { RemainingFilterCredits };
