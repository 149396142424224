import { useThunkDispatch } from 'app/store';
import { fetchAllFavorites, removeFavoriteList } from 'app/store/thunks';
import { DeleteFavoriteListRequest, FavoritesRequest } from 'app/util/requests';
import { useState } from 'react';
import styles from './DeleteModal.module.scss';

interface ModalProps {
  colectionId: string;
  showModal: boolean;
  handleClose: () => void;
}

const DeleteModal = ({
  colectionId,
  showModal,
  handleClose,
}: ModalProps): JSX.Element => {
  const showHideClassName = showModal ? 'flex' : 'none';

  const [favoriteParams] = useState<FavoritesRequest>({
    // user_id: 'ckl',
    only_names: false,
  });
  const [deleteFavoriteListParams] = useState<DeleteFavoriteListRequest>({
    // user_id: 'ckl',
    colection_id: '',
  });

  const thunkDispatch = useThunkDispatch();

  const confirmHandler = () => {
    handleClose();
    thunkDispatch(
      removeFavoriteList({
        ...deleteFavoriteListParams,
        colection_id: colectionId,
      })
    ).then(() => thunkDispatch(fetchAllFavorites({ ...favoriteParams })));
  };

  const rejectHandler = () => {
    handleClose();
  };

  return (
    <>
      <div
        className={`${styles[`display-${showHideClassName}`]} ${
          styles['modal-wrapper']
        }`}
      >
        <p className={styles['title']}>
          Are you sure you want to delete this list?
        </p>
        <div className={styles['container']}>
          <button
            className={styles['button']}
            type="button"
            onClick={rejectHandler}
          >
            No, keep it
          </button>
          <button
            className={styles['button']}
            type="button"
            onClick={confirmHandler}
          >
            Yas, delete it
          </button>
        </div>
      </div>
      <button
        className={`${styles[`display-${showHideClassName}`]} ${
          styles['coating']
        }`}
        onClick={handleClose}
      ></button>
    </>
  );
};

export { DeleteModal };
