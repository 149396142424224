import { FavoriteMetrics } from 'app/models';
import {
  AddFavoriteListRequest,
  AddWalletToFavoriteListRequest,
  DeleteFavoriteListRequest,
  DeleteWalletFromFavoriteListRequest,
  FavoriteMetricsRequest,
  FavoritesRequest,
} from 'app/util/requests';
import { FavoritesResponse } from 'app/util/responses';
import { dataHttp } from '../util/http';

const api = dataHttp;

export function getFavorites(
  params: FavoritesRequest
): Promise<FavoritesResponse> {
  return api.get('/v1/me/favorite_wallet', { params });
}

export function deleteFavoriteList(
  params: DeleteFavoriteListRequest
): Promise<string> {
  return api.delete(`/v1/me/favorite_wallet/${params.colection_id}`);
}

export function postFavoriteList(
  params: AddFavoriteListRequest
): Promise<string> {
  return api.post(`/v1/me/favorite_wallet/${params.colection_id}`);
}

export function postWalletToFavoriteList(
  params: AddWalletToFavoriteListRequest
): Promise<string> {
  return api.post(
    `v1/me/favorite_wallet/${params.colection_id}/${params.wallet_addr}`
  );
}

export function deleteWalletFromFavoriteList(
  params: DeleteWalletFromFavoriteListRequest
): Promise<string> {
  return api.delete(
    `v1/me/favorite_wallet/${params.colection_id}/${params.wallet_addr}`
  );
}

export function getFavoriteMetrics(
  params: FavoriteMetricsRequest
): Promise<FavoriteMetrics> {
  const { name, snapshot } = params;
  return api.get(`/v1/me/favorite_wallet_metrics/${name}`, {
    params: { snapshot },
  });
}
