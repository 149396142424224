import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToastModel } from 'app/util/interfaces';
import { RootState } from '../store';

const NUM_TOASTS = 5;

export interface SharedState {
  toasts: ToastModel[];
}

const initialState: SharedState = {
  toasts: [],
};

export const sharedSlice = createSlice({
  name: 'shared',
  initialState,
  reducers: {
    addToast: (state, action: PayloadAction<ToastModel>) => {
      const toasts: ToastModel[] = [...state.toasts];

      if (toasts.length === NUM_TOASTS) {
        toasts.shift();
      }

      toasts.push(action.payload);
      state.toasts = toasts;
    },
    deleteToast: (state, action: PayloadAction<number>) => {
      state.toasts = state.toasts.filter((t) => t.id !== action.payload);
    },
  },
});

export const sharedActions = sharedSlice.actions;

export const selectToasts = (state: RootState): ToastModel[] =>
  state.shared.toasts;

const sharedReducer = sharedSlice.reducer;

export default sharedReducer;
