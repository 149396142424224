import { useAppSelector } from 'app/store';
import { selectActiveMetadata, selectIsOpen } from 'app/store/slices';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Tooltip } from './Tooltip';
import styles from './Item.module.scss';

type ItemProps = {
  to: string;
  icon: JSX.Element;
  label: string;
};

const Item: FC<ItemProps> = ({ to, icon, label }) => {
  const isSidebarOpen = useAppSelector(selectIsOpen);
  const activeMetadata = useAppSelector(selectActiveMetadata);

  return (
    <li
      className={`${styles.item} ${isSidebarOpen ? styles['item-open'] : ''}`}
    >
      <NavLink
        to={
          activeMetadata ? to + `?snapshot=${activeMetadata.snapshot_id}` : to
        }
        className={({ isActive }) =>
          `${styles.link} ${isActive ? styles['active-link'] : ''}`
        }
      >
        {icon}
        {isSidebarOpen && <span className={styles['link-text']}>{label}</span>}
      </NavLink>
      <Tooltip>{label}</Tooltip>
    </li>
  );
};

export { Item };
