import { ReactNode } from 'react';

import styles from './AuthWrapper.module.scss';
import { ReactComponent as WMCLogo } from 'assets/logos/gold-logo.svg';

interface AuthWrapperProps {
  children: ReactNode;
}

export const AuthWrapper = ({ children }: AuthWrapperProps): JSX.Element => {
  return (
    <div className={styles['auth-container']}>
      <section className={styles['auth-form-container']}>{children}</section>
      <aside className={styles['branding-sidebar']}>
        <h1 className={styles['brand-title']}>Wallet Market Cap</h1>
        <span className={styles['brand-logo']}>
          <WMCLogo />
        </span>
      </aside>
    </div>
  );
};
