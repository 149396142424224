import Axios, { AxiosError } from 'axios';

export const authHttp = Axios.create({
  baseURL:
    process.env.REACT_APP_BRANCH === 'production'
      ? 'https://login.walletmarketcap.com'
      : process.env.REACT_APP_BRANCH === 'staging'
      ? 'http://18.133.237.70:7331' // stage
      : 'https://login.walletmarketcap.com', // dev
});

authHttp.interceptors.response.use(
  (value) => value.data,
  (error: AxiosError) => {
    console.error('Interceptor caught error ===> ', error);
    const code = error.response?.status;

    if (code && code >= 500) {
      window.location.href = '/500';
    } else {
      return Promise.reject(error);
    }
  }
);
