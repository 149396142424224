import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

type InitState = {
  isOpen: boolean;
  showSubPages: boolean;
};

const initialState: InitState = {
  isOpen: false,
  showSubPages: false,
};

const sidebar = createSlice({
  name: 'mainSidebar',
  initialState,
  reducers: {
    setIsOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
    setShowSubPages: (state, action: PayloadAction<boolean>) => {
      state.showSubPages = action.payload;
    },
  },
});

export default sidebar.reducer;

export const { setIsOpen, setShowSubPages } = sidebar.actions;

export const selectIsOpen = (state: RootState) => state.sidebar.isOpen;
export const selectShowSubPages = (state: RootState) =>
  state.sidebar.showSubPages;
