const IconCheckmark = (): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <circle cx="16" cy="16" r="16" fill="#F6E3B3" />
      <path
        d="M22.5 13.5L16.3284 19.6716C14.9951 21.0049 14.3284 21.6716 13.5 21.6716C12.6716 21.6716 12.0049 21.0049 10.6716 19.6716L9 18"
        stroke="#9E7201"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export { IconCheckmark };
