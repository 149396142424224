import styles from './FilterInput.module.scss';
import { UseFormRegister } from 'react-hook-form';
import { logFilterChange } from 'services';

interface FilterInputProps {
  label?: string;
  name: string;
  placeholder?: string;
  register: UseFormRegister<{ [name: string]: string }>;
}

const FilterInput = ({
  label = '',
  name = '',
  placeholder = '',
  register,
}: FilterInputProps): JSX.Element => {
  return (
    <div className={`${styles['filter-input-wrapper']}`}>
      {label.length > 0 && <label className={styles['label']}>{label}</label>}

      <input
        className={`${styles['filter-input']} `}
        placeholder={placeholder}
        type="number"
        {...register(name, {
          onBlur: (e) => logFilterChange(name, e.target.value),
        })}
      />
    </div>
  );
};

export { FilterInput };
