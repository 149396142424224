import { truncateNumber } from 'app/util/helper-functions';
import styles from './SellToken.module.scss';

interface SellTokenProps {
  logo: string;
  value: number;
}

const SellToken = ({ logo, value }: SellTokenProps): JSX.Element => {
  return (
    <div className={styles['sell-token']}>
      <img className="round-image mb-1" src={logo} alt="logo" />
      <div className={styles['sell-token-value']}>${truncateNumber(value)}</div>
    </div>
  );
};

export default SellToken;
