import { truncateNumber } from 'app/util/helper-functions';
import { IconChevron } from 'app/components';
import styles from './BestSellTD.module.scss';
import SellToken from './SellToken/SellToken';

interface SellProps {
  tokenALogo: string;
  tokenAUsdPrice: number;
  tokenBLogo: string;
  tokenBUsdPrice: number;
  roiPercent: number;
  roiUsd: number;
}

const BestSellTD = ({
  tokenALogo,
  tokenAUsdPrice,
  tokenBLogo,
  tokenBUsdPrice,
  roiPercent,
  roiUsd,
}: SellProps): JSX.Element => {
  const isRoiPositive = roiPercent > 0;
  const roiPercentClass = isRoiPositive ? 'color-green' : 'color-red';

  return (
    <div className={styles.sell}>
      <SellToken value={tokenAUsdPrice} logo={tokenALogo} />
      <IconChevron />
      <SellToken value={tokenBUsdPrice} logo={tokenBLogo} />
      <div>
        <div className={`${roiPercentClass} ${styles['roi-values']}`}>
          {isRoiPositive && '+'}
          {truncateNumber(roiPercent)}%
        </div>
        <div className={`mt-2 ${styles.roi} ${styles['roi-values']}`}>
          ${truncateNumber(roiUsd)}
        </div>
      </div>
    </div>
  );
};

export { BestSellTD };
