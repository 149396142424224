import { createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from 'app/util/enums';
import { PlotResponse } from 'app/util/responses';
import { RootState } from '../store';
import { fetchBreakdownPlot } from '../thunks';

const layout = {
  margin: {
    t: 0,
    b: 6,
    l: 0,
    r: 0,
  },
  font: {
    size: 9,
  },
  height: 260,
  width: 300,
  paper_bgcolor: '#292929',
};

export interface PlotsState {
  breakdownPlot: PlotResponse;
  breakdownStatus: LoadingStatus;
  error: string | null;
}

const initialState: PlotsState = {
  error: null,
  breakdownStatus: LoadingStatus.IDLE,
  breakdownPlot: {} as PlotResponse,
};

export const plotsSlice = createSlice({
  name: 'plot',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBreakdownPlot.pending, (state) => {
        state.breakdownStatus = LoadingStatus.PENDING;
      })
      .addCase(fetchBreakdownPlot.fulfilled, (state, action) => {
        state.error = null;
        state.breakdownPlot = {
          data: [...action.payload.data],
          layout: { ...action.payload.layout, ...layout },
          token_contract: [...action.payload.token_contract],
        };
        state.breakdownStatus = LoadingStatus.FULFILLED;
      })
      .addCase(fetchBreakdownPlot.rejected, (state, action) => {
        state.error = action.payload as string;
        state.breakdownStatus = LoadingStatus.REJECTED;
      });
  },
});

export const plotActions = plotsSlice.actions;

export const selectBreakdownPlot = (state: RootState): PlotResponse =>
  state.plots.breakdownPlot;
export const selectBreakdownPlotStatus = (state: RootState): string =>
  state.plots.breakdownStatus;

const plotsReducer = plotsSlice.reducer;

export default plotsReducer;
