import { lazy, Suspense, useEffect, useRef } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import './styles/App.scss';
import { useAppSelector, useThunkDispatch } from 'app/store';
import { defineSubscription, setPageTitle } from 'app/util/helper-functions';
import SuspenseFallback from 'app/components/common/SuspenseFallback/SuspenseFallback';
import { AuthRoute, Toast } from 'app/components';
import {
  fetchOngoingWeekMetadata,
  fetchAllWeeklyMetadata,
  getCredits,
  getDefaultWalletFilters,
  refreshAccessToken,
  fetchAllFavorites,
} from 'app/store/thunks';
import {
  selectIsAuthenticated,
  selectSubscription,
  selectRefreshToken,
  sharedActions,
  authActions,
} from 'app/store/slices';
import Settings from 'app/components/Settings/Settings';
import jwtDecode from 'jwt-decode';
import { SubscriptionType } from 'app/util/interfaces';
import { ServerError } from 'app/components/ServerError';

const ProfileLayout = lazy(
  () => import('app/layouts/ProfileLayout/ProfileLayout')
);
const MainLayout = lazy(() => import('app/layouts/MainLayout/MainLayout'));
const Login = lazy(() => import('app/components/Auth/Login/Login'));
const Signup = lazy(() => import('app/components/Auth/Signup/Signup'));
const AccountVerification = lazy(
  () => import('app/components/AccountVerification/AccountVerification')
);
const ForgotPassword = lazy(
  () => import('app/components/ForgotPassword/ForgotPassword')
);
const ResetPassword = lazy(
  () => import('app/components/ResetPassword/ResetPassword')
);

const MAX_REFRESH_TRIES = 3;

function App(): JSX.Element {
  const thunkDispatch = useThunkDispatch();

  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const subscription = useAppSelector(selectSubscription);
  const refreshToken = useAppSelector(selectRefreshToken);
  const { setHasBoughtSubscription } = authActions;

  const refreshTries = useRef(0);
  const initSubscription = useRef<SubscriptionType | undefined>();
  const intervalId = useRef<ReturnType<typeof setInterval>>();

  const trackSubscriptionChange = async () => {
    const { status } = await thunkDispatch(refreshAccessToken()).unwrap();
    if (typeof status === 'string') {
      return; // error
    }

    const { AccessToken } = status.AuthenticationResult;
    /* eslint-disable-next-line */
    const decodedAccessToken = jwtDecode(AccessToken) as any;

    const subscription = defineSubscription(
      decodedAccessToken['cognito:groups']
    );

    if (typeof initSubscription.current === 'undefined') {
      initSubscription.current = subscription;
    }

    if (subscription !== initSubscription.current) {
      thunkDispatch(
        sharedActions.addToast({
          title: 'Info',
          description: `Subscription has been changed to ${subscription}`,
          id: Date.now(),
        })
      );

      if (
        initSubscription.current === null ||
        initSubscription.current === 'Expired'
      ) {
        thunkDispatch(setHasBoughtSubscription(true));
      }
      initSubscription.current = subscription;
    }
  };

  useEffect(() => {
    setPageTitle();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      trackSubscriptionChange();
      intervalId.current = setInterval(trackSubscriptionChange, 1000 * 10);
    } else {
      initSubscription.current = undefined;
      clearInterval(intervalId.current);
    }

    return () => {
      clearInterval(intervalId.current);
    };
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) return;
    if (!refreshToken) return;
    if (refreshTries.current === MAX_REFRESH_TRIES) return;

    const promise = thunkDispatch(refreshAccessToken());
    refreshTries.current++;

    return () => {
      promise.abort();
    };
  }, [isAuthenticated, refreshToken, thunkDispatch]);

  useEffect(() => {
    if (isAuthenticated && subscription) {
      thunkDispatch(getDefaultWalletFilters());
      thunkDispatch(getCredits());
      thunkDispatch(fetchAllFavorites({ only_names: false }));
      thunkDispatch(fetchOngoingWeekMetadata());
      thunkDispatch(fetchAllWeeklyMetadata());
    }
  }, [isAuthenticated, subscription]);

  const notAuthenticatedRoutes = (
    <>
      <Route path="*" element={<Navigate to="/login" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/verify-account" element={<AccountVerification />} />
      <Route path="/reset-password" element={<ResetPassword />} />
    </>
  );
  const subscriptionRoutes = (
    <>
      <Route
        path="/wallets/:walletId/*"
        element={
          <AuthRoute>
            <ProfileLayout />
          </AuthRoute>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/verify-account" element={<AccountVerification />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route
        path="/*"
        element={
          <AuthRoute>
            <MainLayout />
          </AuthRoute>
        }
      />
    </>
  );
  const noSubscriptionRoutes = (
    <>
      <Route path="*" element={<Navigate to="/settings" />} />
      <Route path="/settings" element={<Settings />} />
    </>
  );

  return (
    <BrowserRouter>
      <Suspense fallback={<SuspenseFallback />}>
        <Routes>
          <Route path="/" element={<Navigate to="/wallets" />} />
          <Route path="/500" element={<ServerError />} />
          {isAuthenticated
            ? subscription && subscription !== 'Expired'
              ? subscriptionRoutes
              : noSubscriptionRoutes
            : notAuthenticatedRoutes}
        </Routes>
      </Suspense>
      <Toast position="bottom-right" />
    </BrowserRouter>
  );
}

export default App;
