import { AnyAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import type { RootState, AppDispatch, store } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useThunkDispatch = () => useDispatch<typeof store.dispatch>();
export const useAppDispatch = (): Dispatch<AnyAction> =>
  useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
