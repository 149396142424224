import { createSlice } from '@reduxjs/toolkit';
import { Wallet } from 'app/models';
import { SlimWallet } from 'app/util/responses';
import { RootState } from '../store';
import { fetchWallet, fetchWallets } from '../thunks';

export interface WalletState {
  item: Wallet;
  items: SlimWallet[];
  itemStatus: 'loading' | 'idle' | 'error';
  itemsStatus: 'loading' | 'idle' | 'error';
  itemError: string;
  itemsError: string;
  itemsTotalPages: number;
}

const initialState: WalletState = {
  item: {} as Wallet,
  items: [],
  itemStatus: 'loading',
  itemsStatus: 'loading',
  itemError: '',
  itemsError: '',
  itemsTotalPages: 0,
};

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWallets.pending, (state) => {
        state.itemsStatus = 'loading';
      })
      .addCase(fetchWallets.fulfilled, (state, action) => {
        state.itemsStatus = 'idle';
        state.items = action.payload.data;
        state.itemsTotalPages = action.payload.page_data.last_page;
      })
      .addCase(fetchWallets.rejected, (state, action) => {
        state.items = [];
        state.itemsError = action.payload as string;
        state.itemsStatus = 'error';
      })
      .addCase(fetchWallet.pending, (state) => {
        state.itemStatus = 'loading';
      })
      .addCase(fetchWallet.fulfilled, (state, action) => {
        state.item = action.payload;
        state.itemStatus = 'idle';
      })
      .addCase(fetchWallet.rejected, (state, action) => {
        state.itemError = action.payload as string;
        state.itemStatus = 'error';
      });
  },
});

export const walletActions = walletSlice.actions;

export const selectWallet = (state: RootState) => state.wallet.item;
export const selectWalletStatus = (state: RootState) => state.wallet.itemStatus;
export const selectWallets = (state: RootState): SlimWallet[] =>
  state.wallet.items;
export const selectWalletsStatus = (state: RootState) =>
  state.wallet.itemsStatus;
export const selectWalletsTotalPages = (state: RootState): number =>
  state.wallet.itemsTotalPages;
export const selectWalletError = (state: RootState) => state.wallet.itemError;
export const selectWalletsError = (state: RootState) => state.wallet.itemsError;

const walletReducer = walletSlice.reducer;

export default walletReducer;
