import Axios, { AxiosError, RawAxiosRequestHeaders } from 'axios';

import { getFromLocalStorage } from '../helper-functions';
console.log("DEEEBUG:",process.env.REACT_APP_BRANCH)
// export const dataHttp = Axios.create({
//     baseURL:
//         process.env.REACT_APP_BRANCH === 'production'
//             ? 'https://api.walletmarketcap.com'
//             : process.env.REACT_APP_BRANCH === 'staging'
//                 ? 'http://127.0.0.1:6969' // stage
//                 : 'http://127.0.0.1:6969', // dev
// });


export const dataHttp = Axios.create({
  baseURL:
    process.env.REACT_APP_BRANCH === 'production'
      ? 'https://api.walletmarketcap.com'
      : process.env.REACT_APP_BRANCH === 'staging'
      ? 'http://18.133.237.70:9696' // stage
      : 'https://api.walletmarketcap.com', // dev
});

dataHttp.interceptors.request.use((config) => {
  const accessToken = getFromLocalStorage('accessToken');
  const headers = config.headers as Partial<RawAxiosRequestHeaders>;
  headers.auth = `Bearer ${accessToken}`;
  return config;
});

dataHttp.interceptors.response.use(
  (value) => value.data,
  (error: AxiosError) => {
    console.error('Interceptor caught error ===> ', error);
    const code = error.response?.status;

    if (code && code >= 500) {
      window.location.href = '/500';
    } else {
      return Promise.reject(error);
    }
  }
);
