import { ChangeEvent } from 'react';
import styles from './Input.module.scss';
import { UseFormRegister, FieldValues, RegisterOptions } from 'react-hook-form';
import { Tooltip, TooltipProps } from 'app/components';

interface InputProps {
  cssClasses?: string;
  disabled?: boolean;
  form?: string;
  label?: string;
  name?: string;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  value?: string;
  type?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  register?: UseFormRegister<FieldValues>;
  validationOptions?: RegisterOptions;
  tooltip?: TooltipProps;
}

const Input = ({
  cssClasses = '',
  disabled = false,
  form = '',
  label = '',
  name = '',
  placeholder = '',
  readOnly = false,
  required = false,
  value = '',
  type = 'text',
  onChange,
  register,
  validationOptions,
  tooltip,
}: InputProps): JSX.Element => {
  const id = Math.random().toString(16);

  return (
    <div className={`${styles['input-wrapper']}`}>
      {label && (
        <div className={styles['label-wrapper']}>
          <label className={styles.label} htmlFor={id}>
            {label}
          </label>
          {tooltip && (
            <Tooltip
              size={tooltip.size}
              text={tooltip.text}
              position={tooltip.position}
            />
          )}
        </div>
      )}
      {register ? (
        <>
          <input
            className={`${styles.input} ${cssClasses}`}
            form={form}
            type={type}
            {...register(name, { ...validationOptions })}
          />
        </>
      ) : (
        <input
          className={`${styles.input} ${cssClasses}`}
          disabled={disabled}
          form={form}
          id={id}
          name={name}
          placeholder={placeholder}
          readOnly={readOnly}
          required={required}
          type={type}
          value={value}
          onChange={onChange}
        />
      )}
    </div>
  );
};

export { Input };
