import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Credits } from 'app/util/interfaces/credits.interface';
import { RootState } from '../store';
import { decreaseCredits, getCredits } from '../thunks/credits.thunks';

interface InitState {
  credits: Credits;
  errorMessage: string;
  status: 'idle' | 'loading' | 'error';
}

const initialState: InitState = {
  credits: {} as Credits,
  errorMessage: '',
  status: 'idle',
};

export const creditsSlice = createSlice({
  name: 'credits',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCredits.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getCredits.fulfilled, (state, action) => {
      state.status = 'idle';
      state.credits = action.payload;
    });
    builder.addCase(
      getCredits.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = 'error';
        state.errorMessage = action.payload;
      }
    );
    builder.addCase(decreaseCredits.fulfilled, (state, action) => {
      state.credits = { ...state.credits, ...action.payload };
    });
  },
});

export const selectCredits = (state: RootState) => state.credits.credits;
export const selectCreditsStatus = (state: RootState) => state.credits.status;

const creditsReducer = creditsSlice.reducer;

export default creditsReducer;
