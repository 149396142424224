import { FC } from 'react';
import { Colors } from 'app/util/colors';

const IconStar: FC<{ isActive: boolean }> = ({ isActive }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 24 24"
    fill={isActive ? Colors['color-primary'] : 'none'}
    stroke={isActive ? Colors['color-primary'] : '#999999'}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
  </svg>
);

export { IconStar };
