import { ChangeEvent, useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './SearchInput.module.scss';
import { IconSearch } from 'app/components/';
import { SearchInputProps } from 'app/util/interfaces/search-input-props.interface';

const SearchInputDesktop = ({
  placeholder,
  value,
  onChange,
  onSearch,
}: SearchInputProps): JSX.Element => {
  const [searchValue, setSearchValue] = useState('');
  const [validation, setValidation] = useState('outline-none');

  const searchHandler = () => {
    setValidation('outline-none');

    if (onSearch && searchValue.length > 0) {
      onSearch(searchValue);
    }
    if (searchValue.match(/^0x[a-fA-F0-9]{40}$/)) {
      setValidation('outline-none');
    } else {
      setValidation('outline-red');
    }
  };

  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
    setValidation('outline-none');

    if (onChange) {
      onChange(value);
    }
  };

  interface ConditionalLinkProps {
    to: string;
    children: JSX.Element;
  }

  const ConditionalLink = ({ children, to }: ConditionalLinkProps) =>
    searchValue.match(/^0x[a-fA-F0-9]{40}$/) ? (
      <Link to={to}>{children}</Link>
    ) : (
      <>{children}</>
    );

  return (
    <div className={`${styles['search-input-wrapper']} ${styles[validation]}`}>
      <input
        className={styles['search-input']}
        placeholder={placeholder}
        type="search"
        value={value}
        spellCheck={false}
        onChange={inputChangeHandler}
      />
      <ConditionalLink to={`/wallets/${searchValue}/dashboard`}>
        <button
          className={styles['search-button']}
          aria-label="search"
          onClick={searchHandler}
        >
          <IconSearch />
        </button>
      </ConditionalLink>
    </div>
  );
};

export { SearchInputDesktop };
