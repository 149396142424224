import { useFormContext } from 'react-hook-form';

import { FilterInput } from 'app/components';
import styles from './WalletFilters.module.scss';

const WalletFilters = (): JSX.Element => {
  const methods = useFormContext();

  return (
    <div className={styles['inputs-wrapper']}>
      <div className={styles['input-wrapper']}>
        <FilterInput
          label="Min Profit"
          name="min_profit"
          placeholder="any"
          {...methods}
        />
        <p className={styles['error-message']}>
          {methods.formState.errors.min_profit?.message ? 'Invalid value!' : ''}
        </p>
      </div>
      <div className={styles['input-wrapper']}>
        <FilterInput
          label="Min Volume"
          placeholder="any"
          name="min_volume"
          {...methods}
        />
        <p className={styles['error-message']}>
          {methods.formState.errors.min_volume?.message
            ? 'Invalid value1!'
            : ''}
        </p>
      </div>
      <div className={styles['input-wrapper']}>
        <FilterInput
          label="Min swap count"
          placeholder="any"
          name="min_trades"
          {...methods}
        />
        <p className={styles['error-message']}>
          {methods.formState.errors.min_trades?.message
            ? 'Invalid value2!'
            : ''}
        </p>
      </div>
      <div className={styles['input-wrapper']}>
        <FilterInput
          label="Min avg invest size"
          placeholder="any"
          name="min_avg_invest_size"
          {...methods}
        />
        <p className={styles['error-message']}>
          {methods.formState.errors.min_avg_invest_size?.message
            ? 'Invalid value!'
            : ''}
        </p>
      </div>
      <div className={styles['input-wrapper']}>
        <FilterInput
          label="Min Win/Loss Ratio"
          placeholder="any"
          name="min_win_loss_ratio"
          {...methods}
        />
        <p className={styles['error-message']}>
          {methods.formState.errors.min_win_loss_ratio?.message
            ? 'Invalid value!'
            : ''}
        </p>
      </div>
      <div className={styles['input-wrapper']}>
        <FilterInput
          label="Max Profit"
          placeholder="any"
          name="max_profit"
          {...methods}
        />
        <p className={styles['error-message']}>
          {methods.formState.errors.max_profit?.message ? 'Invalid value!' : ''}
        </p>
      </div>
      <div className={styles['input-wrapper']}>
        <FilterInput
          label="Max Volume"
          placeholder="any"
          name="max_volume"
          {...methods}
        />
        <p className={styles['error-message']}>
          {methods.formState.errors.max_volume?.message ? 'Invalid value!' : ''}
        </p>
      </div>
      <div className={styles['input-wrapper']}>
        <FilterInput
          label="Max swap count"
          placeholder="any"
          name="max_trades"
          {...methods}
        />
        <p className={styles['error-message']}>
          {methods.formState.errors.max_trades?.message ? 'Invalid value!' : ''}
        </p>
      </div>
      <div className={styles['input-wrapper']}>
        <FilterInput
          label="Max avg invest size"
          placeholder="any"
          name="max_avg_invest_size"
          {...methods}
        />
        <p className={styles['error-message']}>
          {methods.formState.errors.max_avg_invest_size?.message
            ? 'Invalid value!'
            : ''}
        </p>
      </div>
      <div className={styles['input-wrapper']}>
        <FilterInput
          label="Min Avg roi% per trade"
          placeholder="any"
          name="min_avg_roi_per_trade"
          {...methods}
        />
        <p className={styles['error-message']}>
          {methods.formState.errors.min_avg_roi_per_trade?.message
            ? 'Invalid value!'
            : ''}
        </p>
      </div>
    </div>
  );
};

export { WalletFilters };
