import { FC } from 'react';
import styles from './FilterButtonsSection.module.scss';

type FilterButtonsSectionProps = {
  onCancelClick: () => void;
  onResetClick: () => void;
  isDisabledApply: boolean;
};

const FilterButtonsSection: FC<FilterButtonsSectionProps> = (props) => {
  const { onCancelClick, onResetClick, isDisabledApply } = props;
  return (
    <div className={styles['buttons-wrapper']}>
      <button onClick={onCancelClick} type="button" className={styles.button}>
        Cancel
      </button>
      <button onClick={onResetClick} type="button" className={styles.button}>
        Reset
      </button>
      <button
        disabled={isDisabledApply}
        type="submit"
        className={styles.button}
      >
        {isDisabledApply ? <span className={styles['loader']} /> : 'Apply'}
      </button>
    </div>
  );
};

export { FilterButtonsSection };
