export enum Colors {
  'color-primary' = '#e2a302',
  'color-green' = '#109635',
  'color-red' = '#e35252',
  'color-grey' = '#505050',
  'color-lighter-grey' = '#f7f7f7',

  /* Light theme colors */
  'color-light-0' = '#ffffff',
  'color-light-1' = '#e7e7e7',

  /* Dark theme colors */
  'color-dark-0' = '#121212',
  'color-dark-1' = '#292929',
  'color-dark-2' = '#353535',
  'color-dark-3' = '#414141',
  'color-dark-4' = '#717171',
  'color-dark-5' = '#888888',
}
