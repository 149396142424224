import { Subscription } from 'app/util/interfaces';
import { IconCheckmark } from 'app/components';
import styles from './SubscriptionPlanCard.module.scss';
import { getSubscribeRedirectURL } from 'app/api/subscribe.api';
import { useAppDispatch, useAppSelector } from 'app/store';
import { selectSubscription, sharedActions } from 'app/store/slices';
import { useState } from 'react';
import { ShrinkDotsLoader } from '../ShrinkDotsLoader/ShrinkDotsLoader';

interface SubscriptionPlanCardProps extends Subscription {
  showAnnualPrice: boolean;
}

export const SubscriptionPlanCard = ({
  title,
  features,
  priceId,
  isRecommended,
  subtitle,
  cost: { annual, monthly },
  showAnnualPrice,
}: SubscriptionPlanCardProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const subscription = useAppSelector(selectSubscription);
  const [isRedirecting, setIsredirecting] = useState(false);
  const { addToast } = sharedActions;

  const featuresEl = features.map((feature, index) => (
    <li key={index} className={styles['feature']}>
      <IconCheckmark />
      {feature}
    </li>
  ));

  const isTrialUnavailable =
    title === 'Trial' &&
    (subscription === 'Trial' || subscription === 'Expired');
  const hasSubscription =
    subscription && subscription !== 'Trial' && subscription !== 'Expired';

  const subscribe = async () => {
    if (isTrialUnavailable) {
      return dispatch(
        addToast({
          title: 'Info',
          description: 'This subscription is not available anymore!',
          id: Date.now(),
        })
      );
    }

    if (hasSubscription) {
      return dispatch(
        addToast({
          title: 'Info',
          description:
            'Want to change subscription? Choose one from manage subscription button!',
          id: Date.now(),
        })
      );
    }

    setIsredirecting(true);
    const subscriptionId =
      typeof priceId === 'string'
        ? priceId
        : showAnnualPrice
        ? priceId.yearly
        : priceId.monthly;
    const { checkoutUrl } = await getSubscribeRedirectURL(subscriptionId);
    const subcribeTab = window.open();
    if (subcribeTab && 'opener' in subcribeTab) {
      subcribeTab.opener = null;
      subcribeTab.location = checkoutUrl;
      setIsredirecting(false);
    }
  };

  return (
    <div
      className={`${styles.card} ${
        !isRecommended && styles['non-recommended-subscription']
      }`}
    >
      {isRecommended && (
        <div className={styles['recommended-banner']}>Recommended!</div>
      )}
      <header className={styles['card-header']}>
        <h2 className={`${styles['primary-text']} color-white mt-1 mb-2`}>
          {title}
        </h2>
        <p className={`${styles['secondary-text']} mb-1`}>{subtitle}</p>

        <h2 className={`${styles['primary-text']} color-white`}>
          ${showAnnualPrice ? annual : monthly}
        </h2>
        {title !== 'Trial' && (
          <>
            <span className={styles['secondary-text']}>
              per {showAnnualPrice ? 'year' : 'month'}
            </span>

            <span className={styles['secondary-text']}>
              Billed {showAnnualPrice ? 'each year' : 'each month'}
            </span>
          </>
        )}
      </header>

      <ul className={styles['features-list']}>{featuresEl}</ul>

      <button
        className={`button button-primary ${styles['choose-button']} ${
          isTrialUnavailable || hasSubscription
            ? styles['choose-button-disabled']
            : ''
        }`}
        onClick={subscribe}
      >
        {isRedirecting ? <ShrinkDotsLoader /> : `Choose ${title}`}
      </button>
    </div>
  );
};
