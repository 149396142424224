import { dataHttp } from 'app/util/http';
import { MetadataResponse } from 'app/util/responses';

const client = dataHttp;

export function getOngoingWeekMetadata(): Promise<MetadataResponse> {
  return client.get('/v1/metadata');
}

export function getAllWeeklyMetadata(): Promise<MetadataResponse[]> {
  return client.get('/v1/snapshot/all_weekly');
}
