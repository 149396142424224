import wmcLogo from 'assets/logos/gold-logo.svg';
import styles from './Header.module.scss';
import {
  IconButton,
  IconMenu,
  SearchInput,
  IconSettings,
} from 'app/components';
import { Menu } from './Menu';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/store';
import { selectActiveMetadata, setIsMenuOpen } from 'app/store/slices';
import { MetadataBox } from './MetadataBox/MetadataBox';

export const Header: FC = () => {
  const dispatch = useAppDispatch();
  const activeMetadata = useAppSelector(selectActiveMetadata);

  const openMenu = () => {
    dispatch(setIsMenuOpen(true));
  };

  return (
    <header className={styles.header}>
      <Menu />
      <div className={styles.col}>
        <IconButton onClick={openMenu}>
          <IconMenu />
        </IconButton>
        <h2 className={styles.logo}>
          <Link
            to={
              activeMetadata
                ? `/wallets?snapshot=${activeMetadata.snapshot_id}`
                : '/wallets'
            }
          >
            <img
              src={wmcLogo}
              width={47}
              height={47}
              alt="Wallet Market Cap logo"
            />
            <span className={styles['logo-text']}>Wallet Market Cap</span>
          </Link>
        </h2>
      </div>
      <div className={styles.col}>
        <MetadataBox />
      </div>
      <div className={styles.col}>
        <SearchInput placeholder="Search wallet address/Token Contract" />
        <div className={styles['settings-box']}>
          <Link to="/settings">
            <IconSettings />
          </Link>
        </div>
      </div>
    </header>
  );
};
