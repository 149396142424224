import { useThunkDispatch } from 'app/store';
import { ChangeEvent, FC, useState } from 'react';
import { FormEvent } from 'react';
import { changePassword } from 'app/store/thunks';
import styles from './ProfileTab.module.scss';
import { Input, Button, IconLogout } from 'app/components';
import { authActions } from 'app/store/slices';

const ProfileTab: FC = () => {
  const [profileDetails, setProfileDetails] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const thunkDispatch = useThunkDispatch();
  const onProfileSave = (event: FormEvent) => {
    event.preventDefault();

    if (profileDetails.newPassword !== profileDetails.confirmNewPassword) {
      return;
    }

    thunkDispatch(
      changePassword({
        old_password: profileDetails.currentPassword,
        new_password: profileDetails.newPassword,
      })
    );
  };
  const onProfileDetailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setProfileDetails((currentDetails) => ({
      ...currentDetails,
      [event.target.name]: event.target.value,
    }));
  };

  const handleLogout = () => {
    thunkDispatch(authActions.logout());
  };

  return (
    <div className={styles['profile-section']}>
      <p>Change your password</p>
      <form onSubmit={onProfileSave}>
        <div className="mt-4">
          <div className={styles['inputs-wrapper']}>
            <Input
              required
              label="Password"
              type="password"
              name="currentPassword"
              value={profileDetails.currentPassword}
              onChange={onProfileDetailChange}
            />

            <Input
              required
              label="New Password"
              type="password"
              name="newPassword"
              value={profileDetails.newPassword}
              onChange={onProfileDetailChange}
            />

            <Input
              required
              label="Confirm New Password"
              type="password"
              name="confirmNewPassword"
              value={profileDetails.confirmNewPassword}
              onChange={onProfileDetailChange}
            />
          </div>

          <Button cssClasses="bg-primary" type="submit">
            Save
          </Button>
        </div>
      </form>
      <div className={styles.hr}></div>
      <button
        className={`button button-medium bg-red ${styles['logout-button']}`}
        onClick={handleLogout}
      >
        <IconLogout />
        <span>Logout</span>
      </button>
    </div>
  );
};

export { ProfileTab };
