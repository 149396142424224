import styles from './ExpandButton.module.scss';
import { FC } from 'react';
import { IconExpand } from '../Icons/IconExpand';

type ExpandButtonProps = {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const ExpandButton: FC<ExpandButtonProps> = ({ onClick }) => {
  return (
    <button onClick={onClick} className={styles['expand-button']}>
      <IconExpand />
    </button>
  );
};

export default ExpandButton;
