const IconSwapBuy = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.77441 5.5H16.9956M16.9956 5.5L11.885 1M16.9956 5.5L11.885 10"
        stroke="#BFBFBF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2241 13.5H1.00293M1.00293 13.5L6.11354 18M1.00293 13.5L6.11354 9"
        stroke="#BFBFBF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { IconSwapBuy };
