import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

export interface FormState {
  isReset: boolean;
}

const initialState: FormState = {
  isReset: false,
};

export const FormSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setReset: (state, action: PayloadAction<boolean>) => {
      state.isReset = action.payload;
    },
  },
});

export const formActions = FormSlice.actions;

export const isReset = (state: RootState): boolean => state.reset.isReset;

const FormReducer = FormSlice.reducer;

export default FormReducer;
