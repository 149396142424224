import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDefaultWalletFilters as fetchDefaultWalletFilters } from 'app/api/filter.api';

enum FilterThunks {
  wallet = 'getDefaultWalletFilters',
}

export const getDefaultWalletFilters = createAsyncThunk(
  FilterThunks.wallet,
  () => {
    return fetchDefaultWalletFilters();
  }
);
