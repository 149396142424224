import { MultiSwap } from 'app/models';
import { FC, useEffect, useState } from 'react';
import {
  getContractsWithoutLogos,
  getContractsWithoutRouters,
  trimWalletAddress,
  truncateNumber,
} from 'app/util/helper-functions';
import { useAppSelector, useThunkDispatch } from 'app/store';
import {
  selectActiveMetadata,
  selectFavorites,
  selectLogos,
  selectRouters,
} from 'app/store/slices';
import { fetchRouters, fetchTokenLogos } from 'app/store/thunks';
import styles from './MultiSwapTableRow.module.scss';
import { FavoritesListModal, IconStar } from 'app/components';
import { Link } from 'react-router-dom';

type MultiSwapTableRowProps = {
  multiSwap: MultiSwap;
  from: 'arbitrages' | 'sandwich';
};

const MultiSwapTableRow: FC<MultiSwapTableRowProps> = ({ multiSwap, from }) => {
  const thunkDispatch = useThunkDispatch();
  const logos = useAppSelector(selectLogos);
  const routers = useAppSelector(selectRouters);
  const [showAddToFavorites, setShowAddToFavorites] = useState(false);
  const favorites = useAppSelector(selectFavorites);
  const activeMetadata = useAppSelector(selectActiveMetadata);

  useEffect(() => {
    const contractsWithoutLogos = getContractsWithoutLogos(
      multiSwap.tokens_traded,
      logos
    );
    if (contractsWithoutLogos.length) {
      thunkDispatch(fetchTokenLogos(contractsWithoutLogos));
    }

    const contractsWithoutRouters = getContractsWithoutRouters(
      multiSwap.swap_routers_used,
      routers
    );
    if (contractsWithoutRouters.length) {
      thunkDispatch(fetchRouters(contractsWithoutRouters));
    }
  }, [multiSwap]);

  const isChecked = (id: string) => {
    return favorites.some(({ collection_items }) =>
      collection_items.includes(id)
    );
  };

  return (
    <tr>
      <td>{new Date(multiSwap.unix_time * 1000).toLocaleString()}</td>

      <td>
        <Link
          to={
            activeMetadata
              ? `/wallets/${multiSwap.wallet}/dashboard?snapshot=${activeMetadata.snapshot_id}`
              : `/wallets/${multiSwap.wallet}/dashboard`
          }
          className={styles['custom-link']}
        >
          {trimWalletAddress(multiSwap.wallet)}
        </Link>
      </td>
      <td
        className={
          multiSwap.profit > 0
            ? 'color-green'
            : multiSwap.profit < 0
            ? 'color-red'
            : ''
        }
      >
        {truncateNumber(multiSwap.profit)}
      </td>
      <td>{truncateNumber(multiSwap.multi_trade_size)}</td>
      <td>
        <div className={styles['tokens-wrapper']}>
          {multiSwap.tokens_traded.map((token, i) => (
            <img
              src={logos[token]?.image?.small}
              width={40}
              height={40}
              alt="Token"
              key={i}
            />
          ))}
        </div>
      </td>
      <td>
        <div className={styles['tokens-wrapper']}>
          {multiSwap.swap_routers_used.map((router, i) => (
            <img
              src={routers[router]?.img_small}
              width={40}
              height={40}
              alt={routers[router]?.label}
              key={i}
            />
          ))}
        </div>
      </td>
      <td>
        <div className={styles['add-favorite-box']}>
          <button
            className={styles['star']}
            aria-label="favorite"
            onClick={() => setShowAddToFavorites(true)}
          >
            <IconStar isActive={isChecked(multiSwap.wallet)} />
          </button>
          <FavoritesListModal
            show={showAddToFavorites}
            handleClose={() => setShowAddToFavorites(false)}
            wallet={multiSwap.wallet}
          />
        </div>
      </td>
      <td>
        <Link
          to={
            activeMetadata
              ? `/multiswap-overview?wallet=${multiSwap.wallet}&related_swap_ids=${multiSwap.related_swap_ids}&from=${from}&snapshot=${activeMetadata.snapshot_id}`
              : `/multiswap-overview?wallet=${multiSwap.wallet}&related_swap_ids=${multiSwap.related_swap_ids}&from=${from}`
          }
          className={styles['learn-more-link']}
        >
          Learn more
        </Link>
      </td>
    </tr>
  );
};

export { MultiSwapTableRow };
