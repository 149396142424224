import { createAsyncThunk } from '@reduxjs/toolkit';
import { SwapsRequest } from 'app/util/requests';
import { getSwaps as fetchSwaps } from 'app/api';

enum WalletSwapsThunks {
  GET_SWAPS = 'getSwaps',
}

export const getSwaps = createAsyncThunk(
  WalletSwapsThunks.GET_SWAPS,
  (params: SwapsRequest) => {
    return fetchSwaps(params);
  }
);
