import { dataHttp } from 'app/util/http';
import { MultiSwapsRequest } from 'app/util/requests';
import { MultiSwapsResponse } from 'app/util/responses';

const client = dataHttp;

export function fetchMultiSwaps(
  params?: MultiSwapsRequest
): Promise<MultiSwapsResponse> {
  return client.get('/v1/multi_swaps', { params });
}
