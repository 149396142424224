import { useAppDispatch, useAppSelector } from 'app/store';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './WalletTopSection.module.scss';
import {
  copyToClipboard,
  formatWalletTypes,
  trimWalletAddress,
} from 'app/util/helper-functions';
import { selectFavorites, selectWallet, sharedActions } from 'app/store/slices';
import {
  IconStar,
  FavoritesListModal,
  IconButton,
  IconCopy,
} from 'app/components';

const WalletTopSection: FC = () => {
  const dispatch = useAppDispatch();
  const { walletId } = useParams<{ walletId: string }>();
  const wallet = useAppSelector(selectWallet);
  const favorites = useAppSelector(selectFavorites);
  const [showAddFavorite, setShowAddFavorite] = useState(false);

  if (!walletId) {
    return null;
  }

  const handleCopyWallet = async () => {
    await copyToClipboard(walletId);
    dispatch(
      sharedActions.addToast({
        title: 'Info',
        description: 'Copied to the clipboard!',
        id: Date.now(),
      })
    );
  };

  const isChecked = (id: string) => {
    return favorites.some(({ collection_items }) =>
      collection_items.includes(id)
    );
  };

  return (
    <div className={styles.box}>
      <div className={styles['top-line']}>
        <span>{trimWalletAddress(walletId)}</span>
        <IconButton onClick={handleCopyWallet}>
          <IconCopy />
        </IconButton>
        <div className={styles['favorites-wrapper']}>
          <IconButton onClick={() => setShowAddFavorite(true)}>
            <IconStar isActive={isChecked(walletId)} />
          </IconButton>
          <FavoritesListModal
            show={showAddFavorite}
            handleClose={() => setShowAddFavorite(false)}
            wallet={walletId}
          />
        </div>
      </div>
      <p>
        Types:{' '}
        <span>
          {Object.keys(wallet).length &&
            formatWalletTypes({
              is_mev_bot: wallet.is_mev_bot,
              is_high_freq_bot: wallet.is_high_freq_bot,
              is_flash_botter: wallet.is_flash_botter,
              is_top_trader: wallet.is_top_trader,
              is_arbitrageur: wallet.is_arbitrageur,
              is_sandwicher: wallet.is_sandwicher,
              is_sandwich_victim: wallet.is_sandwich_victim,
              is_human: wallet.is_human,
            })}
        </span>
      </p>
    </div>
  );
};

export { WalletTopSection };
