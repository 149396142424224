import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Metadata } from 'app/util/interfaces';
import { RootState } from '../store';
import { fetchOngoingWeekMetadata, fetchAllWeeklyMetadata } from '../thunks';

const getSnapshotSP = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const snapshot = searchParams.get('snapshot');

  return snapshot ? snapshot : '';
};

type MetadataState = {
  ongoingWeekMetadata: Metadata | null;
  allWeeklyMetadata: Metadata[];
  activeMetadata: Metadata | null;
  activeFilter: 'All snapshots' | 'Year 2023' | 'Year 2022' | 'Year 2021';
};

const initialState: MetadataState = {
  ongoingWeekMetadata: null,
  allWeeklyMetadata: [],
  activeMetadata: null,
  activeFilter: 'All snapshots',
};

const metadata = createSlice({
  name: 'metadata',
  initialState,
  reducers: {
    setActiveMetadata: (state, action: PayloadAction<Metadata | null>) => {
      state.activeMetadata = action.payload;
    },
    setActiveFilter: (
      state,
      action: PayloadAction<
        'All snapshots' | 'Year 2023' | 'Year 2022' | 'Year 2021'
      >
    ) => {
      state.activeFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOngoingWeekMetadata.fulfilled, (state, action) => {
        state.ongoingWeekMetadata = {
          ...action.payload,
          creation_time: action.payload.creation_time * 1000,
          data_end_time: action.payload.data_end_time * 1000,
          data_start_time: action.payload.data_start_time * 1000,
        };
      })
      .addCase(fetchAllWeeklyMetadata.fulfilled, (state, action) => {
        state.allWeeklyMetadata = action.payload.map((weekMetadata) => ({
          ...weekMetadata,
          data_start_time: weekMetadata.data_start_time * 1000,
          data_end_time: weekMetadata.data_end_time * 1000,
          creation_time: weekMetadata.creation_time * 1000,
        }));

        const snapshotSP = getSnapshotSP();
        if (!snapshotSP) {
          return;
        }
        action.payload.forEach((weekMetadata, i) => {
          if (weekMetadata.snapshot_id === snapshotSP) {
            state.activeMetadata = { ...action.payload[i] };
          }
        });
      });
  },
});

export const { setActiveFilter, setActiveMetadata } = metadata.actions;

export const selectOngoingWeekMetadata = (state: RootState) =>
  state.metadata.ongoingWeekMetadata;
export const selectAllWeeklyMetadata = (state: RootState) =>
  state.metadata.allWeeklyMetadata;
export const selectActiveFilter = (state: RootState) =>
  state.metadata.activeFilter;
export const selectActiveMetadata = (state: RootState) =>
  state.metadata.activeMetadata;

export default metadata.reducer;
