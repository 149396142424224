import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import authReducer from './slices/auth.slice';
import swapReducer from './slices/swap.slice';
import sharedReducer from './slices/shared.slice';
import buyReducer from './slices/buy.slice';
import walletReducer from './slices/wallet.slice';
import FormReducer from './slices/form.slice';
import tokenReducer from './slices/token.slice';
import simulatorReducer from './slices/simulator.slice';
import favoriteReducer from './slices/favorite.slice';
import plotsReducer from './slices/plots.slice';
import header from './slices/header.slice';
import creditsReducer from './slices/credits.slice';
import filterReducer from './slices/filter.slice';
import trialRemainingData from './slices/trialRemainingData.slice';
import metadataReducer from './slices/metadata.slice';
import routersReducer from './slices/routers.slice';
import walletSwapsReducer from './slices/walletSwaps.slice';
import multiSwapsReducer from './slices/multiSwaps.slice';
import sidebarReducer from './slices/sidebar.slice';

export const store = configureStore({
  reducer: {
    swap: swapReducer,
    wallet: walletReducer,
    shared: sharedReducer,
    auth: authReducer,
    buy: buyReducer,
    reset: FormReducer,
    token: tokenReducer,
    simulator: simulatorReducer,
    favorite: favoriteReducer,
    plots: plotsReducer,
    header,
    credits: creditsReducer,
    filter: filterReducer,
    trialRemainingData,
    metadata: metadataReducer,
    routers: routersReducer,
    walletSwaps: walletSwapsReducer,
    multiSwaps: multiSwapsReducer,
    sidebar: sidebarReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
