import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'app/store';
import { selectToasts, sharedActions } from 'app/store/slices';
import {
  IconToastDanger,
  IconToastInfo,
  IconToastSuccess,
  IconToastWarning,
} from 'app/components';
import styles from './Toast.module.scss';

interface ToastProps {
  position: string;
  autoDelete?: boolean;
  autoDeleteTime?: number;
}

const Toast = ({
  position,
  autoDelete = true,
  autoDeleteTime = 4000,
}: ToastProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const toasts = useAppSelector(selectToasts);

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete && toasts.length) {
        deleteToast(toasts[0].id);
      }
    }, autoDeleteTime);

    return () => {
      clearInterval(interval);
    };
  }, [toasts, autoDelete, autoDeleteTime]);

  const deleteToast = (id: number) => {
    dispatch(sharedActions.deleteToast(id));
  };

  return (
    <div className={`${styles['notification-container']} ${styles[position]}`}>
      {toasts.map((toast) => (
        <div
          key={toast.id}
          className={`${styles['notification']} ${styles['toast']} ${styles[position]}`}
          style={{
            backgroundColor:
              toast.title === 'Danger'
                ? 'red'
                : toast.title === 'Success'
                ? 'green'
                : toast.title === 'Info'
                ? 'teal'
                : toast.title === 'Warning'
                ? 'orange'
                : 'gold',
          }}
        >
          <button onClick={() => deleteToast(toast.id)}>X</button>
          <div className={styles['notification-image']}>
            {toast.title === 'Danger' ? (
              <IconToastDanger />
            ) : toast.title === 'Info' ? (
              <IconToastInfo />
            ) : toast.title === 'Success' ? (
              <IconToastSuccess />
            ) : toast.title === 'Warning' ? (
              <IconToastWarning />
            ) : null}
          </div>
          <div>
            <p className={styles['notification-title']}>{toast.title}</p>
            <p className={styles['notification-message']}>
              {toast.description}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export { Toast };
