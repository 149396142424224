import { Favorite } from 'app/models';
import styles from './Select.module.scss';

interface SelectProps {
  value: string;
  options: Favorite[];
  onChange: () => void;
  onBlur: () => void;
}

function Select({
  value,
  options,
  onChange,
  onBlur,
}: SelectProps): JSX.Element {
  return (
    <select
      value={value}
      onChange={onChange}
      className={styles['select']}
      onBlur={onBlur}
    >
      {options.map((option, idx) => (
        <option value={option.name} key={idx}>
          {option.name}
        </option>
      ))}
    </select>
  );
}

export { Select };
