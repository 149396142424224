import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

type InitState = {
  showBanner: boolean;
};

const initialState: InitState = {
  showBanner: true,
};

const trialRemainingData = createSlice({
  name: 'trialRemainingData',
  initialState,
  reducers: {
    setShowBanner: (state, action: PayloadAction<boolean>) => {
      state.showBanner = action.payload;
    },
  },
});

export const { setShowBanner } = trialRemainingData.actions;

export default trialRemainingData.reducer;

export const selectShowBanner = (state: RootState) =>
  state.trialRemainingData.showBanner;
