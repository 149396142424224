import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getCredits as getCreditsRequest,
  decreaseCredits as decreaseCreditRequest,
} from 'app/api';
import { DecreaseCreditRequest } from 'app/util/requests/decrease-credits.request';

enum CreditsThunks {
  GET = 'credits/getCredits',
  DECREASE = 'credits/decreaseCredit',
}

export const getCredits = createAsyncThunk(CreditsThunks.GET, () => {
  return getCreditsRequest();
});

export const decreaseCredits = createAsyncThunk(
  CreditsThunks.DECREASE,
  (credit: DecreaseCreditRequest) => {
    return decreaseCreditRequest(credit);
  }
);
