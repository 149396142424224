import styles from './Loader.module.scss';

const Loader = (): JSX.Element => {
  return (
    <div className={styles['loader-container']}>
      <svg
        className={styles['loader-svg']}
        width="50"
        height="50"
        viewBox="0 0 100 100"
      >
        <polyline
          className={`${styles['line-cornered']} ${styles['stroke-still']}`}
          points="0,0 100,0 100,100"
          strokeWidth="10"
          fill="none"
        ></polyline>
        <polyline
          className={`${styles['line-cornered']} ${styles['stroke-still']}`}
          points="0,0 0,100 100,100"
          strokeWidth="10"
          fill="none"
        ></polyline>
        <polyline
          className={`${styles['line-cornered']} ${styles['stroke-animation']}`}
          points="0,0 100,0 100,100"
          strokeWidth="10"
          fill="none"
        ></polyline>
        <polyline
          className={`${styles['line-cornered']} ${styles['stroke-animation']}`}
          points="0,0 0,100 100,100"
          strokeWidth="10"
          fill="none"
        ></polyline>
      </svg>
    </div>
  );
};

export { Loader };
