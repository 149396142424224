const IconLeftRightArrows = () => (
  <svg width={16} height={17} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.053 5.585H15m0 0L10.526 1.88M15 5.585l-4.474 3.706M9.947 12.173H1m0 0 4.474 3.706M1 12.173l4.474-3.705"
      stroke="#BFBFBF"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { IconLeftRightArrows };
