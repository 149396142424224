import styles from './StyledNavLink.module.scss';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { useAppSelector } from 'app/store';
import { selectActiveMetadata } from 'app/store/slices';

type StyledNavLinkProps = {
  to: string;
  children: string;
  onClick?: () => void;
};

const StyledNavLink: FC<StyledNavLinkProps> = ({ to, children, onClick }) => {
  const activeMetadta = useAppSelector(selectActiveMetadata);

  return (
    <NavLink
      onClick={onClick}
      to={activeMetadta ? to + `?snapshot=${activeMetadta.snapshot_id}` : to}
      className={({ isActive }) =>
        isActive ? styles['nav-link-active'] : styles['nav-link']
      }
    >
      {children}
    </NavLink>
  );
};

export { StyledNavLink };
