import { ChangeEvent, useState } from 'react';

import { Input } from 'app/components';
import { useThunkDispatch } from 'app/store';
import { addFavoriteList, fetchAllFavorites } from 'app/store/thunks';
import { FavoritesRequest } from 'app/util/requests';
import styles from './AddListsModal.module.scss';
import { sharedActions } from 'app/store/slices';

interface ModalProps {
  showModal: boolean;
  handleClose: () => void;
}

const AddListsModal = ({ showModal, handleClose }: ModalProps): JSX.Element => {
  const showHideClassName = showModal ? 'flex' : 'none';

  const thunkDispatch = useThunkDispatch();

  const [name, setName] = useState('');

  const [favoriteParams] = useState<FavoritesRequest>({
    // user_id: 'ckl',
    only_names: false,
  });

  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const submitHandler = () => {
    if (name.length >= 3) {
      thunkDispatch(
        addFavoriteList({
          colection_id: name,
        })
      ).then(() => thunkDispatch(fetchAllFavorites({ ...favoriteParams })));
      setName('');
    } else {
      thunkDispatch(
        sharedActions.addToast({
          title: 'Warning',
          description: 'Minimal collection length is 3 characters',
          id: Date.now(),
        })
      );
    }
  };

  return (
    <>
      <div
        className={`${styles[`display-${showHideClassName}`]} ${
          styles['modal-wrapper']
        }`}
      >
        <p className={styles['title']}>Add new favourite list name</p>
        <Input
          name="list"
          type="text"
          value={name}
          onChange={inputChangeHandler}
        />

        <button
          className={styles['button']}
          type="submit"
          onClick={submitHandler}
        >
          Add new list
        </button>
      </div>
      <button
        type="reset"
        className={`${styles[`display-${showHideClassName}`]} ${
          styles['coating']
        }`}
        onClick={handleClose}
      ></button>
    </>
  );
};

export { AddListsModal };
