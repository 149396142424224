import { FC } from 'react';
import styles from './FullscreenLoaderContainer.module.scss';

type FullscreenLoaderContainerProps = {
  children: JSX.Element;
};

const FullscreenLoaderContainer: FC<FullscreenLoaderContainerProps> = ({
  children,
}) => {
  return (
    <div className={styles['fullscreen-loader-container']}>{children}</div>
  );
};

export { FullscreenLoaderContainer };
