import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRouter, getRouters } from 'app/api';

enum RoutersThunks {
  GET_ROUTER = 'routers/getRouter',
  GET_ROUTERS = 'routers/getRouters',
}

export const fetchRouter = createAsyncThunk(
  RoutersThunks.GET_ROUTER,
  (contractAddress: string) => {
    return getRouter(contractAddress);
  }
);

export const fetchRouters = createAsyncThunk(
  RoutersThunks.GET_ROUTERS,
  (contractAddresses: string[]) => {
    return getRouters(contractAddresses);
  }
);
