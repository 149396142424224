import { FC, useRef } from 'react';
import { MetadataBox } from '../MetadataBox/MetadataBox';
import styles from './Menu.module.scss';
import { StyledNavLink } from '../StyledNavLink';
import { Divider, IconButton, IconClose } from '../../../index';
import { useAppDispatch, useAppSelector } from 'app/store';
import { selectIsMenuOpen, setIsMenuOpen } from 'app/store/slices';
import { useClickAwayListener } from 'services/hooks/useClickAwayListener';
import { Route } from 'app/models/route.model';

const Menu: FC = () => {
  const dispatch = useAppDispatch();
  const isMenuOpen = useAppSelector(selectIsMenuOpen);
  const menuRef = useRef(null);

  useClickAwayListener(menuRef, () => {
    dispatch(setIsMenuOpen(false));
  });

  const hideMenu = () => {
    dispatch(setIsMenuOpen(false));
  };

  const routes: Route[] = [
    { to: '/wallets', label: 'Wallets' },
    { to: '/swaps', label: 'Best Swaps' },
    { to: '/buys', label: 'Hot Buys' },
    { to: '/simulator', label: 'Profit Simulator' },
    { to: '/history', label: 'Simulation History' },
    { to: '/favorites-overview', label: 'Favorites Overview' },
    { to: '/arbitrages', label: 'Arbitrages' },
    { to: '/sandwich', label: 'Sandwich' },
  ];

  return (
    <div
      className={[styles.menu, isMenuOpen ? styles['menu-open'] : ''].join(' ')}
      ref={menuRef}
    >
      <div className={styles['menu-header']}>
        <IconButton onClick={hideMenu}>
          <IconClose></IconClose>
        </IconButton>
      </div>
      <Divider></Divider>
      <MetadataBox />
      <Divider></Divider>
      <div className={styles['menu-body']}>
        <ul>
          {routes.map((route) => (
            <li key={route.label}>
              <StyledNavLink onClick={hideMenu} to={route.to}>
                {route.label}
              </StyledNavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export { Menu };
