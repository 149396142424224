import { IconProps } from 'app/util/interfaces';
import { Colors } from 'app/util/colors';

const IconSearch = ({ cssClasses }: IconProps): JSX.Element => {
  return (
    <svg
      className={cssClasses}
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 19L15.15 14.65M17.5 9C17.5 13.4183 13.9183 17 9.5 17C5.08172 17 1.5 13.4183 1.5 9C1.5 4.58172 5.08172 1 9.5 1C13.9183 1 17.5 4.58172 17.5 9Z"
        stroke={Colors['color-dark-5']}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { IconSearch };
