const IconCircleX = () => (
  <svg width={35} height={35} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m22.45 12.55-9.9 9.9m0-9.9 9.9 9.9M34 17.5C34 26.613 26.613 34 17.5 34S1 26.613 1 17.5 8.387 1 17.5 1 34 8.387 34 17.5Z"
      stroke="#999"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { IconCircleX };
