import { FC } from 'react';
import styles from './Tooltip.module.scss';

type TooltipProps = {
  children: string;
};

const Tooltip: FC<TooltipProps> = ({ children }) => {
  return <div className={styles['tooltip']}>{children}</div>;
};

export { Tooltip };
