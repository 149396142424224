import { useState } from 'react';

import { useAppSelector, useThunkDispatch } from 'app/store';
import { selectFavorites, sharedActions } from 'app/store/slices';
import {
  addWalletToFavoriteList,
  fetchAllFavorites,
  removeWalletFromFavoriteList,
} from 'app/store/thunks';
import { FavoritesRequest } from 'app/util/requests';
import styles from './FavoritesListModal.module.scss';
import { DeleteModal } from './DeleteModal/DeleteModal';
import { AddListsModal } from './AddListsModal/AddListsModal';
import { logFavorite } from 'services';

interface ModalProps {
  wallet: string;
  show: boolean;
  handleClose: () => void;
}

const FavoritesListModal = ({
  wallet,
  show,
  handleClose,
}: ModalProps): JSX.Element => {
  const showHideClassName = show ? 'flex' : 'none';

  const [colectionId, setColectionId] = useState('');
  const [openAddListsModal, setOpenAddListsModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [favoriteParams] = useState<FavoritesRequest>({
    only_names: false,
  });

  const favorites = useAppSelector(selectFavorites);

  const thunkDispatch = useThunkDispatch();

  const checkHandler = (name: string, id: string) => {
    if (isChecked(name, id)) {
      thunkDispatch(
        removeWalletFromFavoriteList({
          colection_id: name,
          wallet_addr: wallet,
        })
      ).then(() => thunkDispatch(fetchAllFavorites({ ...favoriteParams })));
    } else {
      logFavorite();
      const validWalletRegExp = /^0x[a-fA-F0-9]{40}$/;
      if (validWalletRegExp.test(wallet)) {
        thunkDispatch(
          addWalletToFavoriteList({
            colection_id: name,
            wallet_addr: wallet,
          })
        ).then(() => thunkDispatch(fetchAllFavorites({ ...favoriteParams })));
      } else {
        thunkDispatch(
          sharedActions.addToast({
            title: 'Warning',
            description: 'Wallet has not matched validation!',
            id: Date.now(),
          })
        );
      }
    }
  };
  const isChecked = (name: string, id: string) => {
    return favorites
      .filter((item) => item.name === name)
      .some(({ collection_items }) => collection_items.includes(id));
  };

  const showModal = () => {
    setOpenAddListsModal(true);
  };

  const hideModal = () => {
    setOpenAddListsModal(false);
  };

  const deleteHandler = () => {
    setOpenDeleteModal(true);
  };

  const hideDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  return (
    <>
      <div
        className={`${styles[`display-${showHideClassName}`]} ${
          styles['modal-wrapper']
        }`}
      >
        <div>
          {favorites.map((favorite, index) => (
            <div key={index} className={styles['favorite-wrapper']}>
              <label className={styles['checkbox-container']}>
                {favorite.name}
                <input
                  type="checkbox"
                  name={favorite.name}
                  checked={isChecked(favorite.name, wallet)}
                  onChange={() => checkHandler(favorite.name, wallet)}
                />
                <span className={styles['checkmark']} />
              </label>

              <button
                className={styles['delete-button']}
                onClick={() => {
                  setColectionId(favorite.name);
                  deleteHandler();
                }}
              >
                ✖
              </button>
            </div>
          ))}
        </div>
        <button className={styles['add-button']} onClick={showModal}>
          +add new custom list
        </button>
      </div>
      <button
        className={`${styles[`display-${showHideClassName}`]} ${
          styles['coating']
        }`}
        onClick={handleClose}
      ></button>
      <AddListsModal showModal={openAddListsModal} handleClose={hideModal} />
      <DeleteModal
        showModal={openDeleteModal}
        handleClose={hideDeleteModal}
        colectionId={colectionId}
      />
    </>
  );
};

export { FavoritesListModal };
